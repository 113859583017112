import React, { Fragment, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import styles from "../../../styles/auth/auth.module.scss";
import { buttonTypes } from "../../shared/button/utils";
import Button from "../../shared/button/button";
import { Link, useHistory } from "react-router-dom";
import Input from "../../shared/input/input";
import ErrorMessage from "../../shared/error-message/errorMessage";
import formStyles from "./../../shared/input/input.module.scss";
import { MenuItem, FormControl, Select, Typography } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import useStyles from "../style";
import TextField from "@mui/material/TextField";
import {
  createIssueAPICall,
  issueStatusAPICall,
  IssueCloseAPICall,
} from "../../../api/personal.loan.api";
import Loading from "./../../shared/loading/loading";
import ModalComponent from "../../common/Modal";
import TabContext from "@mui/lab/TabContext";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

export default function LoanApplicationForm() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();
  //State variables
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();
  const [issueCategory, setIssueCategory] = useState("PAYMENT");
  const [loanStatus, setLoanStatus] = useState("DISBURSED");
  const [issueDescription, setIssueDescription] = useState("");
  const [showLoanStatusBtn, setShowLoanStatusBtn] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [issueStatusModal, setIssueStatusModal] = useState(false);
  const [issueCloseModal, setIssueCloseModal] = useState(false);
  const [issueStatusMessage, setIssueStatusMessage] = useState("");
  const [issueCloseMessage, setIssueCloseMessage] = useState("");

  const [value, setValue] = React.useState("1");

  const [inlineError, setInlineError] = useState({
    name_error: "",
    email_error: "",
    category_error: "",
    loan_status_error: "",
    mobile_error: "",
  });

  function checkName() {
    if (!name) {
      setInlineError((inlineError) => ({
        ...inlineError,
        name_error: "Name cannot be empty",
      }));
      return false;
    }
    return true;
  }

  function checkEmail() {
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (!email) {
      setInlineError((inlineError) => ({
        ...inlineError,
        email_error: "Email cannot be empty",
      }));
      return false;
    } else if (!email.match(isValidEmail)) {
      setInlineError((inlineError) => ({
        ...inlineError,
        email_error: "Please enter valid Email Id",
      }));
      return false;
    }
    return true;
  }

  function selectLoanStatus() {
    if (!loanStatus) {
      setInlineError((inlineError) => ({
        ...inlineError,
        loan_status_error: "Please select Loan Status",
      }));
      return false;
    }
    return true;
  }

  function checkMobile() {
    const isValidMobile = /^[0-9]{10}$/;
    if (!mobile) {
      setInlineError((inlineError) => ({
        ...inlineError,
        mobile_error: "Phone Number cannot be empty",
      }));
      return false;
    } else if (mobile.length > 10) {
      setInlineError((inlineError) => ({
        ...inlineError,
        mobile_error: "Your mobile number exceeds 10 digits",
      }));
      return false;
    }
    return true;
  }

  function selectIssueCategory() {
    if (!issueCategory) {
      setInlineError((inlineError) => ({
        ...inlineError,
        category_error: "Please select issue category",
      }));
      return false;
    }
    return true;
  }

  const getIssueDetails = () => {
    return {
      name: name,
      mobile: mobile,
      issueType: issueCategory,
      loanStatus: loanStatus,
      issueDescription: issueDescription,
    };
  };

  const makeIssueCloseAPICall = async () => {
    setIsLoading(true);
    const formData = getIssueDetails();
    try {
      const data = await IssueCloseAPICall(formData, "CLOSED");
      if (data.message.ack.status === "ACK") {
        setIssueCloseMessage("Your issue got closed");
        setIsLoading(false);
      } else {
        alert("Some thing went wrong and please try again.");
        setIssueCloseMessage("");
        setIsLoading(false);
      }
    } catch (err) {
      console.log("error in issueClosesAPICall API", err);
    } finally {
    }
  };

  const makeIssueStatusAPICall = async () => {
    setIsLoading(true);
    try {
      const data = await issueStatusAPICall();
      setIsLoading(false);
      setIssueStatusMessage(data[0].message.issue.resolution.long_desc);
    } catch (err) {
      console.log("error in makeIssueStatusAPICall API", err);
    } finally {
    }
  };

  const makeCreateIssueAPICall = async () => {
    setIsLoading(true);
    const formData = getIssueDetails();
    try {
      const data = await createIssueAPICall(formData, "OPEN");
      sessionStorage.setItem("issueID", data[0].message.issue.id);
      setIsLoading(false);
      alert("Your issue got created Successfully");
    } catch (err) {
      console.log("error in makeCreateIssueAPICall API", err);
    } finally {
    }
  };

  const checkForIssueStatus = () => {
    if (sessionStorage.getItem("issueID")) {
      setShowLoanStatusBtn(true);
    } else {
      setIssueStatusModal(true);
    }
  };

  const checkForIssueClose = () => {
    if (sessionStorage.getItem("issueID")) {
      setShowLoanStatusBtn(true);
    } else {
      setIssueCloseModal(true);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === "2") {
      checkForIssueStatus();
    } else if (newValue === "3") {
      checkForIssueClose();
    }
  };

  // useEffect(() => {
  //   if (sessionStorage.getItem("issueID")) {
  //     setShowLoanStatusBtn(true);
  //   }
  // }, []);

  function submitIssueInfo(e) {
    e.preventDefault();
    makeCreateIssueAPICall();
  }

  return (
    <div id="issueCreateForm">
      {isLoading ? (
        <Loading />
      ) : (
        <Fragment>
          {errorModal ? (
            <ModalComponent
              open={errorModal}
              onClose={() => {
                setErrorModal(false);
                history.push("/application/home");
              }}
              title={"Error !"}
            >
              <div className={classes.colorBlue}>
                Something went wrong and please try again.
              </div>

              <Typography component="div" my={2} align="center" color="#196aab">
                <Button
                  button_type={buttonTypes.primary}
                  button_text="OK"
                  button_hover_type={buttonTypes.primary_hover}
                  onClick={(e) => {
                    history.push(`/application/home`);
                  }}
                >
                  OK
                </Button>
              </Typography>
            </ModalComponent>
          ) : (
            <div className={styles.auth_form}>
              <Typography component="div" mt={2}>
                <Link to="/application/home" className={classes.backToHome}>
                  &#60; Back to Home
                </Link>
              </Typography>
              <Typography
                component="div"
                variant="h3"
                mb={2}
                mt={3}
                align="center"
                color="#196aab"
              >
                Issue Governance
              </Typography>

              <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
                <TabContext value={value}>
                  <TabList onChange={handleChange}>
                    <Tab
                      label="Create Issue"
                      value="1"
                      className="deafult-font"
                    />
                    <Tab
                      label="Check Issue Status"
                      value="2"
                      className="deafult-font"
                    />
                    <Tab
                      label="Close issue"
                      value="3"
                      className="deafult-font"
                    />
                  </TabList>
                  <TabPanel value="1" sx={{ p: 0 }}>
                    <form onSubmit={submitIssueInfo}>
                      <Grid container columnSpacing={10} mt={2}>
                        <Grid xs={12} md={6}>
                          <Input
                            id="name"
                            name="name"
                            type="text"
                            placeholder="Enter name"
                            label_name="Name"
                            autoComplete="off"
                            has_error={inlineError.name_error}
                            onChange={(event) => {
                              setName(event.target.value);
                              setInlineError((inlineError) => ({
                                ...inlineError,
                                name_error: "",
                              }));
                            }}
                            onBlur={checkName}
                            required
                          />
                          {inlineError.name_error && (
                            <ErrorMessage>
                              {inlineError.name_error}
                            </ErrorMessage>
                          )}
                        </Grid>
                        <Grid xs={12} md={6}>
                          <Input
                            id="email"
                            name="email"
                            type="email"
                            placeholder="Enter Email"
                            label_name="Email"
                            autoComplete="off"
                            has_error={inlineError.email_error}
                            onChange={(event) => {
                              setEmail(event.target.value);
                              setInlineError((inlineError) => ({
                                ...inlineError,
                                email_error: "",
                              }));
                            }}
                            onBlur={checkEmail}
                            required
                          />
                          {inlineError.email_error && (
                            <ErrorMessage>
                              {inlineError.email_error}
                            </ErrorMessage>
                          )}
                        </Grid>
                        <Grid xs={12} md={6}>
                          <Input
                            id="contactNumber"
                            name="contactNumber"
                            type="number"
                            placeholder="Ex: 9543812345"
                            label_name="Contact Number"
                            maxLength="10"
                            autoComplete="off"
                            has_error={inlineError.mobile_error}
                            onKeyUp={(event) => {
                              setMobile(event.target.value);
                              checkMobile();
                            }}
                            onChange={(event) => {
                              setMobile(event.target.value);
                              setInlineError((inlineError) => ({
                                ...inlineError,
                                mobile_error: "",
                              }));
                            }}
                            onBlur={checkMobile}
                            required
                          />
                          {inlineError.mobile_error && (
                            <ErrorMessage>
                              {inlineError.mobile_error}
                            </ErrorMessage>
                          )}
                        </Grid>
                        <Grid xs={12} md={6}>
                          <div className="py-2">
                            <FormControl style={{ width: "100%" }}>
                              <label
                                className={`${formStyles.form_label} ${formStyles.required}`}
                              >
                                Select Loan Status
                              </label>
                              <Select
                                required
                                style={{ height: "45px" }}
                                size="small"
                                labelId="Loan Status"
                                id="loanStatusType"
                                name="loanStatusType"
                                value={loanStatus}
                                label="Select Employment Type"
                                displayEmpty
                                onChange={(e) => {
                                  setInlineError((error) => ({
                                    ...inlineError,
                                    loan_status_error: "",
                                  }));
                                  setLoanStatus(e.target.value);
                                }}
                                onBlur={selectLoanStatus}
                              >
                                <MenuItem value="DISBURSED">Disbursed</MenuItem>
                                <MenuItem value="INITIATED">Initiated</MenuItem>
                                <MenuItem value="CLOSED">Closed</MenuItem>
                              </Select>
                              {inlineError.loan_status_error && (
                                <ErrorMessage>
                                  {inlineError.loan_status_error}
                                </ErrorMessage>
                              )}
                            </FormControl>
                          </div>
                        </Grid>
                        <Grid xs={12} md={6}>
                          <div className="py-2">
                            <FormControl style={{ width: "100%" }}>
                              <label
                                id="issueCategory"
                                className={`${formStyles.form_label} ${formStyles.required}`}
                              >
                                Issue Category
                              </label>
                              <Select
                                style={{ height: "45px" }}
                                required
                                size="small"
                                labelId="Issue Category"
                                id="issueCategory"
                                name="issueCategory"
                                value={issueCategory}
                                label="issueCategory"
                                onChange={(e) => {
                                  setInlineError((error) => ({
                                    ...inlineError,
                                    category_error: "",
                                  }));
                                  setIssueCategory(e.target.value);
                                }}
                                onBlur={selectIssueCategory}
                              >
                                <MenuItem value="PAYMENT">Payments</MenuItem>
                                <MenuItem value="PRECLOSURE">
                                  Pre Closure
                                </MenuItem>
                                <MenuItem value="FORECLOSURE">
                                  Fore Closure
                                </MenuItem>
                                <MenuItem value="MISSEDEMI">
                                  Missed EMI
                                </MenuItem>
                              </Select>
                              {inlineError.category_error && (
                                <ErrorMessage>
                                  {inlineError.category_error}
                                </ErrorMessage>
                              )}
                            </FormControl>
                          </div>
                        </Grid>
                        <Grid xs={12} md={6}>
                          <div>
                            <label
                              id="issueDescrption"
                              className={`${formStyles.form_label} ${formStyles.required}`}
                            >
                              Issue Description
                            </label>
                          </div>
                          <TextField
                            //placeholder="Enter Issue description"
                            multiline
                            fullWidth="true"
                            required="true"
                            //size="medium"
                            rows={1}
                            maxRows={4}
                            onChange={(e) => {
                              setIssueDescription(e.target.value);
                            }}
                          />
                        </Grid>

                        <Grid xs={12}>
                          <div className="py-4 text-center">
                            <Button
                              isloading={isLoading ? 1 : 0}
                              disabled={isLoading}
                              button_type={buttonTypes.primary}
                              button_hover_type={buttonTypes.primary_hover}
                              button_text="Create Issue"
                              type="submit"
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </form>
                  </TabPanel>
                  <TabPanel value="2" sx={{ p: 0 }}>
                    <Grid xs={12}>
                      <div className="py-4 text-center">
                        {issueStatusMessage ? (
                          <Typography component="div" my={2} color="#196aab">
                            {/* Your issue is under */}
                            <div
                              style={{ fontWeight: "bold", marginTop: "10px" }}
                            >
                              {issueStatusMessage}{" "}
                            </div>
                          </Typography>
                        ) : (
                          <Typography
                            component="div"
                            variant="h6"
                            my={2}
                            align="center"
                            color="#196aab"
                          >
                            Please click below button to check Issue Status
                          </Typography>
                        )}
                        <Button
                          isloading={isLoading ? 1 : 0}
                          disabled={isLoading}
                          button_type={buttonTypes.primary}
                          button_hover_type={buttonTypes.primary_hover}
                          button_text="Check Issue Status"
                          onClick={(e) => {
                            makeIssueStatusAPICall();
                          }}
                        />
                      </div>
                    </Grid>
                    <ModalComponent
                      open={issueStatusModal}
                      onClose={() => {
                        setIssueStatusModal(false);
                        handleChange("", "1");
                      }}
                      title={"Issue Status"}
                    >
                      <div className={classes.colorBlue}>
                        You don't have any open issue to check Status.
                      </div>

                      <Typography
                        component="div"
                        my={2}
                        align="center"
                        color="#196aab"
                      >
                        <Button
                          button_type={buttonTypes.primary}
                          button_text="OK"
                          button_hover_type={buttonTypes.primary_hover}
                          onClick={(e) => {
                            handleChange("", "1");
                            //history.push(`/application/IssueGovernance`);
                          }}
                        >
                          OK
                        </Button>
                      </Typography>
                    </ModalComponent>
                  </TabPanel>
                  <TabPanel value="3" sx={{ p: 0 }}>
                    <Grid xs={12}>
                      <div className="py-4">
                        {issueCloseMessage ? (
                          <Typography
                            component="div"
                            my={2}
                            color="#196aab"
                            align="center"
                            variant="h5"
                          >
                            {issueCloseMessage}
                          </Typography>
                        ) : (
                          <form onSubmit={makeIssueCloseAPICall}>
                            <Grid container columnSpacing={10} mt={2}>
                              <Grid xs={12} md={6}>
                                <Input
                                  id="name"
                                  name="name"
                                  type="text"
                                  placeholder="Enter name"
                                  label_name="Name"
                                  autoComplete="off"
                                  has_error={inlineError.name_error}
                                  onChange={(event) => {
                                    setName(event.target.value);
                                    setInlineError((inlineError) => ({
                                      ...inlineError,
                                      name_error: "",
                                    }));
                                  }}
                                  onBlur={checkName}
                                  required
                                />
                                {inlineError.name_error && (
                                  <ErrorMessage>
                                    {inlineError.name_error}
                                  </ErrorMessage>
                                )}
                              </Grid>
                              <Grid xs={12} md={6}>
                                <Input
                                  id="email"
                                  name="email"
                                  type="email"
                                  placeholder="Enter Email"
                                  label_name="Email"
                                  autoComplete="off"
                                  has_error={inlineError.email_error}
                                  onChange={(event) => {
                                    setEmail(event.target.value);
                                    setInlineError((inlineError) => ({
                                      ...inlineError,
                                      email_error: "",
                                    }));
                                  }}
                                  onBlur={checkEmail}
                                  required
                                />
                                {inlineError.email_error && (
                                  <ErrorMessage>
                                    {inlineError.email_error}
                                  </ErrorMessage>
                                )}
                              </Grid>
                              <Grid xs={12} md={6}>
                                <Input
                                  id="contactNumber"
                                  name="contactNumber"
                                  type="number"
                                  placeholder="Ex: 9543812345"
                                  label_name="Contact Number"
                                  maxLength="10"
                                  autoComplete="off"
                                  has_error={inlineError.mobile_error}
                                  onKeyUp={(event) => {
                                    setMobile(event.target.value);
                                    checkMobile();
                                  }}
                                  onChange={(event) => {
                                    setMobile(event.target.value);
                                    setInlineError((inlineError) => ({
                                      ...inlineError,
                                      mobile_error: "",
                                    }));
                                  }}
                                  onBlur={checkMobile}
                                  required
                                />
                                {inlineError.mobile_error && (
                                  <ErrorMessage>
                                    {inlineError.mobile_error}
                                  </ErrorMessage>
                                )}
                              </Grid>
                              <Grid xs={12} md={6}>
                                <div className="py-2">
                                  <FormControl style={{ width: "100%" }}>
                                    <label
                                      className={`${formStyles.form_label} ${formStyles.required}`}
                                    >
                                      Select Loan Status
                                    </label>
                                    <Select
                                      required
                                      style={{ height: "45px" }}
                                      size="small"
                                      labelId="Loan Status"
                                      id="loanStatusType"
                                      name="loanStatusType"
                                      value={loanStatus}
                                      label="Select Employment Type"
                                      displayEmpty
                                      onChange={(e) => {
                                        setInlineError((error) => ({
                                          ...inlineError,
                                          loan_status_error: "",
                                        }));
                                        setLoanStatus(e.target.value);
                                      }}
                                      onBlur={selectLoanStatus}
                                    >
                                      <MenuItem value="DISBURSED">
                                        Disbursed
                                      </MenuItem>
                                      <MenuItem value="INITIATED">
                                        Initiated
                                      </MenuItem>
                                      <MenuItem value="CLOSED">Closed</MenuItem>
                                    </Select>
                                    {inlineError.loan_status_error && (
                                      <ErrorMessage>
                                        {inlineError.loan_status_error}
                                      </ErrorMessage>
                                    )}
                                  </FormControl>
                                </div>
                              </Grid>
                              <Grid xs={12} md={6}>
                                <div className="py-2">
                                  <FormControl style={{ width: "100%" }}>
                                    <label
                                      id="issueCategory"
                                      className={`${formStyles.form_label} ${formStyles.required}`}
                                    >
                                      Issue Category
                                    </label>
                                    <Select
                                      style={{ height: "45px" }}
                                      required
                                      size="small"
                                      labelId="Issue Category"
                                      id="issueCategory"
                                      name="issueCategory"
                                      value={issueCategory}
                                      label="issueCategory"
                                      onChange={(e) => {
                                        setInlineError((error) => ({
                                          ...inlineError,
                                          category_error: "",
                                        }));
                                        setIssueCategory(e.target.value);
                                      }}
                                      onBlur={selectIssueCategory}
                                    >
                                      <MenuItem value="PAYMENT">
                                        Payments
                                      </MenuItem>
                                      <MenuItem value="PRECLOSURE">
                                        Pre Closure
                                      </MenuItem>
                                      <MenuItem value="FORECLOSURE">
                                        Fore Closure
                                      </MenuItem>
                                      <MenuItem value="MISSEDEMI">
                                        Missed EMI
                                      </MenuItem>
                                    </Select>
                                    {inlineError.category_error && (
                                      <ErrorMessage>
                                        {inlineError.category_error}
                                      </ErrorMessage>
                                    )}
                                  </FormControl>
                                </div>
                              </Grid>
                              <Grid xs={12} md={6}>
                                <div>
                                  <label
                                    id="issueDescrption"
                                    className={`${formStyles.form_label} ${formStyles.required}`}
                                  >
                                    Issue Description
                                  </label>
                                </div>
                                <TextField
                                  //placeholder="Enter Issue description"
                                  multiline
                                  fullWidth="true"
                                  required="true"
                                  //size="medium"
                                  rows={1}
                                  maxRows={4}
                                  onChange={(e) => {
                                    setIssueDescription(e.target.value);
                                  }}
                                />
                              </Grid>
                            </Grid>
                            <div className="py-4 text-center">
                              <Button
                                isloading={isLoading ? 1 : 0}
                                disabled={isLoading}
                                button_type={buttonTypes.primary}
                                button_hover_type={buttonTypes.primary_hover}
                                button_text="Close Issue"
                                onClick={(e) => {
                                  makeIssueCloseAPICall();
                                }}
                                //type="submit"
                              />
                            </div>
                          </form>
                        )}
                      </div>
                    </Grid>
                    <ModalComponent
                      open={issueCloseModal}
                      onClose={() => {
                        setIssueCloseModal(false);
                        handleChange("", "1");
                        //history.push("/application/home");
                      }}
                      title={"Close Status"}
                    >
                      <div className={classes.colorBlue}>
                        You don't have any open issue to close.
                      </div>

                      <Typography
                        component="div"
                        my={2}
                        align="center"
                        color="#196aab"
                      >
                        <Button
                          button_type={buttonTypes.primary}
                          button_text="OK"
                          button_hover_type={buttonTypes.primary_hover}
                          onClick={(e) => {
                            handleChange("", "1");
                            //history.push(`/application/IssueGovernance`);
                          }}
                        >
                          OK
                        </Button>
                      </Typography>
                    </ModalComponent>
                  </TabPanel>
                </TabContext>
              </Box>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
}
