import React, { Fragment, useEffect, useState } from "react";
import styles from "../../../styles/auth/auth.module.scss";
import { buttonTypes } from "../../shared/button/utils";
import Button from "../../shared/button/button";
import { Link, useHistory } from "react-router-dom";
import Input from "../../shared/input/input";
import ErrorMessage from "../../shared/error-message/errorMessage";
import formStyles from "./../../shared/input/input.module.scss";
import { MenuItem, FormControl, Select, Typography } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import useStyles from "../style";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { format } from "date-fns";
import {
  formSubmitAPICall,
  finvuAPICall,
  selectAPICall,
  selectApproveAPICall,
} from "../../../api/personal.loan.api";
import Loading from "./../../shared/loading/loading";
import ModalComponent from "../../common/Modal";
import kyc from "../../../assets/images/kyc.png";

export default function LoanApplicationForm() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();
  //State variables
  const [fname, setFname] = useState();
  const [lname, setLname] = useState();
  const [pemail, setPersonalEmail] = useState();
  const [oemail, setOfficialEmail] = useState();
  const [DOB, setDOB] = useState();
  const [gender, setGender] = useState("male");
  const [pan, setPan] = useState();
  const [mobile, setMobile] = useState();
  const [employmentType, setEmploymentType] = useState("Salaried");
  const [income, setIncome] = useState();
  const [companyName, setCompanyName] = useState();
  const [udyamNumber, setudyamNumber] = useState();
  const [addressLine1, setAddressLine1] = useState();
  const [addressLine2, setAddressLine2] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [pincode, setPincode] = useState();
  const [accountId, setAccountId] = useState();
  const [loanPurpose, setLoanPurpose] = useState("consumerDurablePurchase");
  const [loanConsent, setLoanConsent] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [finVuUrl, setFinVuUrl] = useState(false);
  const [finVUModalConsent, setFinVUModalConsent] = useState(false);
  const [consentHandlers, setConsentHandlers] = useState(false);
  //const [showloanOffersBtn, setShowLoanOffersBtn] = useState(false);

  const [inlineError, setInlineError] = useState({
    fname_error: "",
    lname_error: "",
    personal_email_error: "",
    official_email_error: "",
    dob_error: "",
    gender_error: "",
    pan_error: "",
    mobile_error: "",
    employment_error: "",
    income_error: "",
    company_error: "",
    udyam_error: "",
    address_line1_error: "",
    address_line2_error: "",
    city_error: "",
    state_error: "",
    pincode_error: "",
    accountId_error: "",
    loan_purpose_error: "",
    consent_error: "",
  });

  function checkFname() {
    if (!fname) {
      setInlineError((inlineError) => ({
        ...inlineError,
        fname_error: "First Name cannot be empty",
      }));
      return false;
    }
    return true;
  }

  function checkLname() {
    if (!lname) {
      setInlineError((inlineError) => ({
        ...inlineError,
        lname_error: "Lat Name cannot be empty",
      }));
      return false;
    }
    return true;
  }

  function checkPersonalEmail() {
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (!pemail) {
      setInlineError((inlineError) => ({
        ...inlineError,
        personal_email_error: "Email cannot be empty",
      }));
      return false;
    } else if (!pemail.match(isValidEmail)) {
      setInlineError((inlineError) => ({
        ...inlineError,
        personal_email_error: "Please enter valid Email Id",
      }));
      return false;
    }
    return true;
  }

  function checkOfficialEmail() {
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (!oemail) {
      setInlineError((inlineError) => ({
        ...inlineError,
        official_email_error: "Email cannot be empty",
      }));
      return false;
    } else if (!oemail.match(isValidEmail)) {
      setInlineError((inlineError) => ({
        ...inlineError,
        official_email_error: "Please enter valid Email Id",
      }));
      return false;
    }
    return true;
  }

  function selectGender() {
    if (!gender) {
      setInlineError((inlineError) => ({
        ...inlineError,
        gender_error: "Please select Gender",
      }));
      return false;
    }
    return true;
  }

  function checkDOB() {
    if (!DOB) {
      setInlineError((inlineError) => ({
        ...inlineError,
        dob_error: "Please select DOB",
      }));
      return false;
    }
    return true;
  }

  function checkPan() {
    const isValidPan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    if (!pan) {
      setInlineError((inlineError) => ({
        ...inlineError,
        pan_error: "PAN cannot be empty",
      }));
      return false;
    } else if (!pan.match(isValidPan)) {
      setInlineError((inlineError) => ({
        ...inlineError,
        pan_error: "Please enter valid PAN number",
      }));
      return false;
    }
    return true;
  }

  function checkMobile() {
    const isValidMobile = /^[0-9]{10}$/;
    if (!mobile) {
      setInlineError((inlineError) => ({
        ...inlineError,
        mobile_error: "Phone Number cannot be empty",
      }));
      return false;
    } else if (!mobile.match(isValidMobile)) {
      setInlineError((inlineError) => ({
        ...inlineError,
        mobile_error: "Please enter valid mobile number",
      }));
      return false;
    }
    return true;
  }

  function selectEmploymentType() {
    if (!employmentType) {
      setInlineError((inlineError) => ({
        ...inlineError,
        employment_error: "Please select Employment Type",
      }));
      return false;
    }
    return true;
  }

  function checkIncome() {
    if (!income) {
      setInlineError((inlineError) => ({
        ...inlineError,
        income_error: "Income cannot be Empty",
      }));
      return false;
    }
    return true;
  }

  function checkCompanyName() {
    if (!companyName) {
      setInlineError((inlineError) => ({
        ...inlineError,
        company_error: "Income cannot be Empty",
      }));
      return false;
    }
    return true;
  }

  function checkUdyamNumber() {
    if (!udyamNumber) {
      setInlineError((inlineError) => ({
        ...inlineError,
        udyam_error: "UDYAM cannot be Empty",
      }));
      return false;
    }
    return true;
  }

  function checkAddressLine1() {
    if (!addressLine1) {
      setInlineError((inlineError) => ({
        ...inlineError,
        address_line1_error: "Address cannot be Empty",
      }));
      return false;
    }
    return true;
  }

  function checkAddressLine2() {
    if (!addressLine2) {
      setInlineError((inlineError) => ({
        ...inlineError,
        address_line2_error: "Address cannot be Empty",
      }));
      return false;
    }
    return true;
  }

  function checkCity() {
    if (!city) {
      setInlineError((inlineError) => ({
        ...inlineError,
        city_error: "City cannot be Empty",
      }));
      return false;
    }
    return true;
  }

  function checkState() {
    if (!state) {
      setInlineError((inlineError) => ({
        ...inlineError,
        state_error: "State cannot be Empty",
      }));
      return false;
    }
    return true;
  }

  function checkPincode() {
    const isValidPincode = /^[0-9]{6}$/;
    if (!pincode) {
      setInlineError((inlineError) => ({
        ...inlineError,
        pincode_error: "Pincode cannot be Empty",
      }));
      return false;
    } else if (!pincode.match(isValidPincode)) {
      setInlineError((inlineError) => ({
        ...inlineError,
        pincode_error: "Please enter valid Pincode",
      }));
      return false;
    }
    return true;
  }

  function checkAccountId() {
    if (!accountId) {
      setInlineError((inlineError) => ({
        ...inlineError,
        accountId_error: "Account ID cannot be Empty",
      }));
      return false;
    }
    return true;
  }

  function selectLoanPurpose() {
    if (!loanPurpose) {
      setInlineError((inlineError) => ({
        ...inlineError,
        loan_purpose_error: "Please select Loan purpose",
      }));
      return false;
    }
    return true;
  }

  function checkConsent() {
    if (!loanConsent) {
      setInlineError((inlineError) => ({
        ...inlineError,
        consent_error: "Please select Loan consent",
      }));
      return false;
    }
    return true;
  }

  useEffect(() => {
    setTimeout(function () {
      document
        .getElementById("customerApplicationForm")
        ?.scrollIntoView({ behavior: "instant", block: "start" });
    }, 200);
  }, []);

  const makeSelectApproveAPICall = async () => {
    setIsLoading(true);
    try {
      const data = await selectApproveAPICall();
      const personalLoanData = data.filter((item) => !item[0].error);
      sessionStorage.setItem(
        "loanEligibilityDetails",
        JSON.stringify(personalLoanData)
      );
      history.push("/application/loanEligibilityDetails");
    } catch (err) {
      console.log("error in makeSelectApproveAPICall API", err);
      setIsLoading(false);
      setErrorModal(true);
    } finally {
    }
  };

  const makeFinvuAPICall = async (consentHandlers) => {
    setIsLoading(true);
    try {
      const data = await finvuAPICall(consentHandlers, accountId);
      setFinVuUrl(data.AAUrl);
      setIsLoading(false);
      history.push("/application/finvuPage");
      window.close();
      //setShowLoanOffersBtn(true);
      window.open(data.AAUrl);
      //makeSelectApproveAPICall();
    } catch (err) {
      console.log("error in makeFinvuAPICall API", err);
      setIsLoading(false);
      setErrorModal(true);
    } finally {
    }
  };

  const makeSelectAPICall = async () => {
    try {
      const data = await selectAPICall();
      let consentHandlersArray = [];
      data.map((item) => {
        if (
          !item[0].error &&
          item[0].message.order.items[0].tags[0]?.list[0]?.descriptor.code ===
            "CONSENT_HANDLER"
        ) {
          consentHandlersArray.push(
            item[0].message.order.items[0].tags[0].list[0].value
          );
        }
      });
      setIsLoading(false);
      setConsentHandlers(consentHandlersArray);
      setFinVUModalConsent(true);
      //makeFinvuAPICall(consentHandlers);
      //makeSelectApproveAPICall();
    } catch (err) {
      console.log("error in makeSelectAPICall API", err);
      setIsLoading(false);
      setErrorModal(true);
    } finally {
    }
  };

  const makeCustomerFormSubmitAPICall = async (formData) => {
    setIsLoading(true);
    try {
      const data = await formSubmitAPICall(formData);
      console.log("submit data==", data);
      //sessionStorage.setItem("customerFormSubmitID", data?.submission_id);
      makeSelectAPICall();
    } catch (err) {
      console.log("error in makeCustomerFormSubmitAPICall API", err);
    } finally {
    }
  };

  function submitCustomerInfo(e) {
    e.preventDefault();
    document
      .getElementById("customerApplicationForm")
      ?.scrollIntoView({ behavior: "instant", block: "start" });
    let customerformData = new FormData();
    customerformData.append("firstName", fname);
    customerformData.append("lastName", lname);
    customerformData.append("dob", format(DOB, "dd-MM-yyyy"));
    customerformData.append("gender", gender);
    customerformData.append("pan", pan);
    customerformData.append("contactNumber", mobile);
    customerformData.append("personalemail", pemail);
    customerformData.append("officialemail", oemail);
    customerformData.append("employmentType", employmentType);
    customerformData.append("endUse", loanPurpose);
    customerformData.append("income", income);
    customerformData.append("companyName", companyName);
    customerformData.append("udyamNumber", udyamNumber);
    customerformData.append("addressL1", addressLine1);
    customerformData.append("addressL2", addressLine2);
    customerformData.append("city", city);
    customerformData.append("state", state);
    customerformData.append("pincode", pincode);
    customerformData.append("aa_id", accountId);
    customerformData.append("bureauConsent", loanConsent ? "on" : "off");
    // const selectedBankInfo = JSON.parse(
    //   sessionStorage.getItem("selectedBankDetails")
    // );
    // customerformData.append(
    //   "formId",
    //   selectedBankInfo?.message.catalog.providers[0].items[0].xinput.form.id
    // );
    makeCustomerFormSubmitAPICall(customerformData);
  }

  return (
    <div id="customerApplicationForm" style={{ scrollMarginTop: "100px" }}>
      {isLoading ? (
        <Loading />
      ) : (
        <Fragment>
          <ModalComponent
            open={finVUModalConsent}
            onClose={() => {
              setFinVUModalConsent(false);
              history.push("/application/home");
            }}
            title={"FinVu Approve Consent"}
          >
            <div className={classes.colorBlue}>
              You hereby undertake and confirm that the information and data
              furnished by you to the platform is true and correct. The platform
              has been integrated with lenders who use Credit Information
              Companies (CIC) to conduct credit checks on body corporates/MSME
              and Individuals. You acknowledge and confirm that you have
              consented to the sharing of sensitive data provided by you with
              such lenders and CICs for the purpose of obtaining the name and
              account number of banks with whom you have an open cash credit or
              overdraft account. In addition, you consent to the data being used
              to faciliate the creation of a credit offer which may include the
              account to which the funds may be disbursed as per prevailing RBI
              norms.
            </div>

            <Typography component="div" my={2} align="center" color="#196aab">
              <Button
                button_type={buttonTypes.primary}
                style={{ marginRight: "20px" }}
                button_text="DISAGREE"
                button_hover_type={buttonTypes.primary_hover}
                onClick={(e) => {
                  history.push(`/application/home`);
                }}
              >
                DISAGREE
              </Button>
              <Button
                button_type={buttonTypes.primary}
                button_text="AGREE"
                button_hover_type={buttonTypes.primary_hover}
                onClick={(e) => {
                  setFinVUModalConsent(false);
                  makeFinvuAPICall(consentHandlers);
                }}
              >
                AGREE
              </Button>
            </Typography>
          </ModalComponent>
          {errorModal ? (
            <ModalComponent
              open={errorModal}
              onClose={() => {
                setErrorModal(false);
                history.push("/application/home");
              }}
              title={"Error !"}
            >
              <div className={classes.colorBlue}>
                Something went wrong and please try again.
              </div>

              <Typography component="div" my={2} align="center" color="#196aab">
                <Button
                  button_type={buttonTypes.primary}
                  button_text="OK"
                  button_hover_type={buttonTypes.primary_hover}
                  onClick={(e) => {
                    history.push(`/application/home`);
                  }}
                >
                  OK
                </Button>
              </Typography>
            </ModalComponent>
          ) : (
            <div className={styles.auth_form}>
              <Typography component="div" mt={2}>
                <Link to="/application/home" className={classes.backToHome}>
                  &#60; Back to Home
                </Link>
              </Typography>
              <Typography
                component="div"
                variant="h3"
                mb={2}
                mt={3}
                align="center"
                color="#196aab"
              >
                Customer Information
              </Typography>
              <form onSubmit={submitCustomerInfo}>
                <Grid container columnSpacing={10} mt={2}>
                  <Grid xs={12} md={6}>
                    <Input
                      id="firstName"
                      name="firstName"
                      type="text"
                      placeholder="Enter first name"
                      label_name="First Name"
                      autoComplete="off"
                      has_error={inlineError.fname_error}
                      onChange={(event) => {
                        setFname(event.target.value);
                        setInlineError((inlineError) => ({
                          ...inlineError,
                          fname_error: "",
                        }));
                      }}
                      onBlur={checkFname}
                      required
                    />
                    {inlineError.fname_error && (
                      <ErrorMessage>{inlineError.fname_error}</ErrorMessage>
                    )}
                  </Grid>
                  <Grid xs={12} md={6}>
                    <Input
                      id="lastName"
                      name="lastName"
                      type="text"
                      placeholder="Enter Last name"
                      label_name="Last Name"
                      autoComplete="off"
                      has_error={inlineError.lname_error}
                      onChange={(event) => {
                        setLname(event.target.value);
                        setInlineError((inlineError) => ({
                          ...inlineError,
                          lname_error: "",
                        }));
                      }}
                      onBlur={checkLname}
                      required
                    />
                    {inlineError.lname_error && (
                      <ErrorMessage>{inlineError.lname_error}</ErrorMessage>
                    )}
                  </Grid>
                  <Grid xs={12} md={6}>
                    <Input
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Enter Personal Email"
                      label_name="Personal Email"
                      autoComplete="off"
                      has_error={inlineError.personal_email_error}
                      onChange={(event) => {
                        setPersonalEmail(event.target.value);
                        setInlineError((inlineError) => ({
                          ...inlineError,
                          personal_email_error: "",
                        }));
                      }}
                      onBlur={checkPersonalEmail}
                      required
                    />
                    {inlineError.personal_email_error && (
                      <ErrorMessage>
                        {inlineError.personal_email_error}
                      </ErrorMessage>
                    )}
                  </Grid>
                  <Grid xs={12} md={6}>
                    <Input
                      id="officialEmail"
                      name="officialEmail"
                      type="email"
                      placeholder="Enter Official Email"
                      label_name="Official Email"
                      autoComplete="off"
                      has_error={inlineError.official_email_error}
                      onChange={(event) => {
                        setOfficialEmail(event.target.value);
                        setInlineError((inlineError) => ({
                          ...inlineError,
                          official_email_error: "",
                        }));
                      }}
                      onBlur={checkOfficialEmail}
                      required
                    />
                    {inlineError.official_email_error && (
                      <ErrorMessage>
                        {inlineError.official_email_error}
                      </ErrorMessage>
                    )}
                  </Grid>
                  <Grid xs={12} md={6}>
                    <div className="py-2">
                      <label
                        id="DOB"
                        className={`${formStyles.form_label} ${formStyles.required}`}
                      >
                        Select Date of Birth
                      </label>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer
                          components={["DatePicker"]}
                          sx={{ height: "55px !important " }}
                        >
                          <DatePicker
                            disableFuture={true}
                            format="DD-MM-YYYY"
                            slotProps={{
                              textField: {
                                required: true,
                              },
                            }}
                            onChange={(date) => {
                              console.log("selected Date===", date.$d);
                              console.log(
                                "selected Date===",
                                format(date.$d, "dd-MM-yyyy")
                              );
                              setDOB(date.$d);
                            }}
                            sx={{ minWidth: "100% !important " }}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </div>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <div className="py-2">
                      <FormControl style={{ width: "100%" }}>
                        <label
                          id="Gender"
                          className={`${formStyles.form_label} ${formStyles.required}`}
                        >
                          Select Gender
                        </label>
                        <Select
                          style={{ height: "45px" }}
                          required
                          size="small"
                          labelId="Gender"
                          id="gender"
                          name="gender"
                          value={gender}
                          label="Select Gender"
                          onChange={(e) => {
                            setInlineError((error) => ({
                              ...inlineError,
                              gender_error: "",
                            }));
                            setGender(e.target.value);
                          }}
                          onBlur={selectGender}
                        >
                          <MenuItem value="male">Male</MenuItem>
                          <MenuItem value="female">Female</MenuItem>
                          <MenuItem value="Transgender">Transgender</MenuItem>
                        </Select>
                        {inlineError.gender_error && (
                          <ErrorMessage>
                            {inlineError.gender_error}
                          </ErrorMessage>
                        )}
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <Input
                      id="pan"
                      name="pan"
                      type="text"
                      placeholder="Enter PAN"
                      label_name="PAN"
                      autoComplete="off"
                      has_error={inlineError.pan_error}
                      onChange={(event) => {
                        setPan(event.target.value);
                        setInlineError((inlineError) => ({
                          ...inlineError,
                          pan_error: "",
                        }));
                      }}
                      onBlur={checkPan}
                      required
                    />
                    {inlineError.pan_error && (
                      <ErrorMessage>{inlineError.pan_error}</ErrorMessage>
                    )}
                  </Grid>
                  <Grid xs={12} md={6}>
                    <Input
                      id="contactNumber"
                      name="contactNumber"
                      type="number"
                      placeholder="Ex: 9543812345"
                      label_name="Contact Number"
                      maxLength="10"
                      autoComplete="off"
                      has_error={inlineError.mobile_error}
                      onKeyUp={(event) => {
                        setMobile(event.target.value);
                        checkMobile();
                        const mobileNo = event.target.value;
                        setAccountId(`${mobileNo}@finvu`);
                      }}
                      onChange={(event) => {
                        setMobile(event.target.value);
                        setInlineError((inlineError) => ({
                          ...inlineError,
                          mobile_error: "",
                        }));
                      }}
                      onBlur={checkMobile}
                      required
                    />
                    {inlineError.mobile_error && (
                      <ErrorMessage>{inlineError.mobile_error}</ErrorMessage>
                    )}
                  </Grid>
                  <Grid xs={12} md={6}>
                    <div className="py-2">
                      <FormControl style={{ width: "100%" }}>
                        <label
                          className={`${formStyles.form_label} ${formStyles.required}`}
                        >
                          Employment Type
                        </label>
                        <Select
                          required
                          style={{ height: "45px" }}
                          size="small"
                          labelId="employmentType"
                          id="employmentType"
                          name="employmentType"
                          value={employmentType}
                          label="Select Employment Type"
                          displayEmpty
                          onChange={(e) => {
                            setInlineError((error) => ({
                              ...inlineError,
                              employment_error: "",
                            }));
                            setEmploymentType(e.target.value);
                          }}
                          onBlur={selectEmploymentType}
                        >
                          <MenuItem value="Salaried">Salaried</MenuItem>
                          <MenuItem value="Self Employment">
                            Self Employment
                          </MenuItem>
                        </Select>
                        {inlineError.employment_error && (
                          <ErrorMessage>
                            {inlineError.employment_error}
                          </ErrorMessage>
                        )}
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <Input
                      id="income"
                      name="income"
                      type="number"
                      placeholder="Enter your Income"
                      label_name="Income"
                      autoComplete="off"
                      has_error={inlineError.income_error}
                      onChange={(event) => {
                        setIncome(event.target.value);
                        setInlineError((inlineError) => ({
                          ...inlineError,
                          income_error: "",
                        }));
                      }}
                      onBlur={checkIncome}
                      required
                    />
                    {inlineError.income_error && (
                      <ErrorMessage>{inlineError.income_error}</ErrorMessage>
                    )}
                  </Grid>
                  <Grid xs={12} md={6}>
                    <Input
                      id="companyName"
                      name="companyName"
                      type="text"
                      placeholder="Enter Company Name"
                      label_name="Company Name"
                      autoComplete="off"
                      has_error={inlineError.company_error}
                      onChange={(event) => {
                        setCompanyName(event.target.value);
                        setInlineError((inlineError) => ({
                          ...inlineError,
                          company_error: "",
                        }));
                      }}
                      onBlur={checkCompanyName}
                      required
                    />
                  </Grid>
                  <Grid xs={12} md={6}>
                    <Input
                      id="udyamNumber"
                      name="udyamNumber"
                      type="number"
                      placeholder="Ex : 1234567"
                      label_name="UDYAM Number"
                      autoComplete="off"
                      has_error={inlineError.udyam_error}
                      onChange={(event) => {
                        setudyamNumber(event.target.value);
                        setInlineError((inlineError) => ({
                          ...inlineError,
                          udyam_error: "",
                        }));
                      }}
                      onBlur={checkUdyamNumber}
                      required
                    />
                    <a
                      href="https://udyamregistration.gov.in/UAM-convert-udyam-msme-free-registration.htm"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Get UDYAM Number
                    </a>
                    {inlineError.udyam_error && (
                      <ErrorMessage>{inlineError.udyam_error}</ErrorMessage>
                    )}
                  </Grid>
                  <Grid xs={12} md={6}>
                    <Input
                      id="addressL1"
                      name="addressL1"
                      type="text"
                      placeholder="Enter Your Address"
                      label_name="Address Line1"
                      autoComplete="off"
                      has_error={inlineError.address_line1_error}
                      onChange={(event) => {
                        setAddressLine1(event.target.value);
                        setInlineError((inlineError) => ({
                          ...inlineError,
                          address_line1_error: "",
                        }));
                      }}
                      onBlur={checkAddressLine1}
                      required
                    />
                    {inlineError.address_line1_error && (
                      <ErrorMessage>
                        {inlineError.address_line1_error}
                      </ErrorMessage>
                    )}
                  </Grid>
                  <Grid xs={12} md={6}>
                    <Input
                      id="addressL2"
                      name="addressL2"
                      type="text"
                      placeholder="Enter Your Address"
                      label_name="Address Line2"
                      autoComplete="off"
                      has_error={inlineError.address_line2_error}
                      onChange={(event) => {
                        setAddressLine2(event.target.value);
                        setInlineError((inlineError) => ({
                          ...inlineError,
                          address_line2_error: "",
                        }));
                      }}
                      onBlur={checkAddressLine2}
                      required
                    />
                    {inlineError.address_line2_error && (
                      <ErrorMessage>
                        {inlineError.address_line2_error}
                      </ErrorMessage>
                    )}
                  </Grid>
                  <Grid xs={12} md={6}>
                    <Input
                      id="city"
                      name="city"
                      type="text"
                      placeholder="Enter Your City"
                      label_name="City"
                      autoComplete="off"
                      has_error={inlineError.city_error}
                      onChange={(event) => {
                        setCity(event.target.value);
                        setInlineError((inlineError) => ({
                          ...inlineError,
                          city_error: "",
                        }));
                      }}
                      onBlur={checkCity}
                      required
                    />
                    {inlineError.city_error && (
                      <ErrorMessage>{inlineError.city_error}</ErrorMessage>
                    )}
                  </Grid>
                  <Grid xs={12} md={6}>
                    <Input
                      id="state"
                      name="state"
                      type="text"
                      placeholder="Enter Your state"
                      label_name="State"
                      autoComplete="off"
                      has_error={inlineError.state_error}
                      onChange={(event) => {
                        setState(event.target.value);
                        setInlineError((inlineError) => ({
                          ...inlineError,
                          state_error: "",
                        }));
                      }}
                      onBlur={checkState}
                      required
                    />
                    {inlineError.state_error && (
                      <ErrorMessage>{inlineError.state_error}</ErrorMessage>
                    )}
                  </Grid>
                  <Grid xs={12} md={6}>
                    <Input
                      id="pincode"
                      name="pincode"
                      type="text"
                      maxLength="6"
                      placeholder="Enter Your Pincode"
                      label_name="Pin Code"
                      autoComplete="off"
                      has_error={inlineError.pincode_error}
                      onChange={(event) => {
                        setPincode(event.target.value);
                        setInlineError((inlineError) => ({
                          ...inlineError,
                          pincode_error: "",
                        }));
                      }}
                      onBlur={checkPincode}
                      required
                    />
                    {inlineError.pincode_error && (
                      <ErrorMessage>{inlineError.pincode_error}</ErrorMessage>
                    )}
                  </Grid>
                  <Grid xs={12} md={6}>
                    <Input
                      id="aa_id"
                      name="aa_id"
                      value={accountId}
                      placeholder="Ex: test@user"
                      label_name="Account Aggregator ID"
                      autoComplete="off"
                      has_error={inlineError.accountId_error}
                      onChange={(event) => {
                        setAccountId(event.target.value);
                        setInlineError((inlineError) => ({
                          ...inlineError,
                          accountId_error: "",
                        }));
                      }}
                      onBlur={checkAccountId}
                      required
                    />
                    <a
                      href="https://sahamati.org.in/aa-apps/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Get Account Aggregator ID
                    </a>
                    {inlineError.accountId_error && (
                      <ErrorMessage>{inlineError.accountId_error}</ErrorMessage>
                    )}
                  </Grid>
                  <Grid xs={12} md={6}>
                    <div className="py-2">
                      <FormControl style={{ width: "100%" }}>
                        <label
                          id="loanPurposeType"
                          className={`${formStyles.form_label} ${formStyles.required}`}
                        >
                          Purpose of Loan
                        </label>
                        <Select
                          required
                          style={{ height: "45px" }}
                          labelId="loanPurposeType"
                          id="endUse"
                          name="endUse"
                          size="small"
                          value={loanPurpose}
                          label="Select Purpose of Loan"
                          onChange={(e) => {
                            setLoanPurpose(e.target.value);
                            setInlineError((error) => ({
                              ...inlineError,
                              loan_purpose_error: "",
                            }));
                          }}
                          onBlur={selectLoanPurpose}
                        >
                          <MenuItem value="consumerDurablePurchase">
                            Purchase of Consumer Durables
                          </MenuItem>
                          <MenuItem value="education">Education</MenuItem>
                          <MenuItem value="travel">Travel</MenuItem>
                          <MenuItem value="health">Health</MenuItem>
                          <MenuItem value="other">
                            Other Consumption Purposes
                          </MenuItem>
                        </Select>
                        {inlineError.loan_purpose_error && (
                          <ErrorMessage>
                            {inlineError.loan_purpose_error}
                          </ErrorMessage>
                        )}
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <div className="py-4">
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="bureauConsent"
                            name="bureauConsent"
                            className="mx-2"
                            checked={loanConsent}
                            required
                            onChange={(e) => {
                              setInlineError((error) => ({
                                ...inlineError,
                                consent_error: "",
                              }));
                              setLoanConsent(e.target.checked);
                            }}
                            onBlur={checkConsent}
                          />
                        }
                        label="Bureau Consent"
                        labelPlacement="end"
                        key="consent"
                      />
                      {inlineError.consent_error && (
                        <ErrorMessage>{inlineError.consent_error}</ErrorMessage>
                      )}
                    </div>
                  </Grid>
                  <Grid xs={12}>
                    <div className="py-4 text-center">
                      <Button
                        isloading={isLoading ? 1 : 0}
                        disabled={isLoading}
                        button_type={buttonTypes.primary}
                        button_hover_type={buttonTypes.primary_hover}
                        button_text="Submit"
                        type="submit"
                      />
                    </div>
                  </Grid>
                </Grid>
              </form>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
}
