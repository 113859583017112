import React, { useState } from "react";
import useStyles from "./style";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import ModalComponent from "../../common/Modal";
import LoanStausForm from "../../../components/personalLoan/loanStatusForm/loanStatusForm";

import logo from "../../../assets/images/AppLogo.svg";
import { ReactComponent as UserIcon } from "../../../assets/images/loggedInUser.svg";
import { useHistory } from "react-router-dom";
import { deleteAllCookies } from "../../../utils/cookies";
import { getUser, isLoggedIn } from "../../../utils/validateToken";

const NavBar = () => {
  const classes = useStyles();
  const history = useHistory();
  const user = getUser();
  const [anchorElUserMenu, setAnchorElUserMenu] = useState(null);
  const [loanStatusModal, setLoanStatusModal] = useState(false);
  const openUserMenu = Boolean(anchorElUserMenu);

  const handleClickUserMenu = (event) => {
    setAnchorElUserMenu(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUserMenu(null);
  };

  return (
    <>
      <AppBar position="absolute">
        <Toolbar className={classes.headerContainer}>
          <img
            src={logo}
            alt="logo"
            className={classes.appLogo}
            onClick={() => {
              // removeCookie("search_context");
              console.log("clicked");
              history.push("/application/home");
            }}
          />

          <>
            <div
              className={classes.inputContainer}
              style={{ textAlign: "center" }}
            >
              <h2>Personal Loan Portal</h2>
            </div>

            <div
              className={classes.user}
              id="basic-button"
              aria-controls={openUserMenu ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openUserMenu ? "true" : undefined}
              onClick={handleClickUserMenu}
            >
              <UserIcon />
              <Typography variant="body2" className={classes.userTypo}>
                {isLoggedIn() && user ? user.name : "User"}
              </Typography>
            </div>
            <Menu
              className={classes.userMenu}
              id="basic-menu"
              anchorEl={anchorElUserMenu}
              open={openUserMenu}
              onClose={handleCloseUserMenu}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                onClick={() => {
                  //setLoanStatusModal(true);
                  history.push("/application/loanStatus");
                }}
              >
                Loan Status
              </MenuItem>
              <MenuItem
                onClick={() => {
                  //setLoanStatusModal(true);
                  history.push("/application/loanPrePayment");
                }}
              >
                Loan Pre Payment
              </MenuItem>
              <MenuItem
                onClick={() => {
                  //setLoanStatusModal(true);
                  history.push("/application/loanForeClosure");
                }}
              >
                Loan ForeClosure
              </MenuItem>
              <MenuItem
                onClick={() => {
                  //setLoanStatusModal(true);
                  history.push("/application/loanEMIMissingDetails");
                }}
              >
                EMI Missing Details
              </MenuItem>
              <MenuItem
                onClick={() => {
                  history.push("/application/IssueGovernance");
                }}
              >
                Issue Governance
              </MenuItem>
              <MenuItem
                onClick={() => {
                  deleteAllCookies();
                  history.replace("/");
                }}
              >
                Logout
              </MenuItem>
            </Menu>
          </>
        </Toolbar>
        {loanStatusModal && (
          <ModalComponent
            open={loanStatusModal}
            onClose={() => {
              setLoanStatusModal(false);
            }}
            title={"Loan Status"}
          >
            <LoanStausForm
              onClose={() => {
                setLoanStatusModal(false);
              }}
            />
          </ModalComponent>
        )}
      </AppBar>
    </>
  );
};

export default NavBar;
