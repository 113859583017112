import React, { Fragment, useState, useEffect } from "react";
import styles from "../../../styles/auth/auth.module.scss";
import ModalComponent from "../../common/Modal";
import { buttonTypes } from "../../shared/button/utils";
import Button from "../../shared/button/button";
import { Link, useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import useStyles from "../style";
import Input from "../../shared/input/input";
import ErrorMessage from "../../shared/error-message/errorMessage";
import { Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";

import Grid from "@mui/material/Grid";
import {
  loanPrepaymentAPICall,
  loanStatusAPICall,
} from "../../../api/personal.loan.api";
import loan_Pre_Payment from "../../../assets/images/loan_Pre_Payment.jpg";

function LoanPrepayment() {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = React.useState(0);

  const classes = useStyles();
  const [loanAmount, setLoanAmount] = useState();
  const [loanDetails, setLoanDetails] = useState();
  const [loanDisbursementDetails, setLoanDisbursementDetails] = useState();
  const [bankName, setBankName] = useState();
  const [bankLogoUrl, setBankLogoUrl] = useState();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [customerDetails, setCustomerDetails] = useState();
  const [paymentDetails, setPaymentDetails] = useState();
  const [cancellationDetails, setCancellationDetails] = useState();
  const [agreementDetails, setagreementDetails] = useState();
  const [showLinkForPrePayment, setShowLinkForPrePayment] = useState(false);
  const [showPrePaymentSuccessContent, setShowPrePaymentSuccessContent] =
    useState(false);
  const [loanStatusModal, setLoanStatusModal] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState();

  const [showPrePaymentStatusBtn, setShowPrePaymentStatusBtn] = useState(false);

  const [showPrePaymentAmountForm, setShowPrePaymentAmountForm] =
    useState(true);

  const [inlineError, setInlineError] = useState({
    loanAmount_error: "",
  });

  const converToDate = (date) => {
    return new Date(date).toISOString().substring(0, 10);
  };

  function checkLoanAmount() {
    if (!loanAmount) {
      setInlineError((inlineError) => ({
        ...inlineError,
        accountName_error: "Loan Pre Payment Amount cannot be empty",
      }));
      return false;
    }
    return true;
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const makeLoanPrepaymentAPICall = async () => {
    try {
      const loanConfirmationData = JSON.parse(
        sessionStorage.getItem("loanConfirmationDetails")
      );
      const data = await loanPrepaymentAPICall(
        loanConfirmationData,
        loanAmount
      );

      const PrepaymentData = data[0].message.order.payments[0];

      if (
        PrepaymentData.status === "NOT-PAID" &&
        PrepaymentData.time.label === "PRE_PART_PAYMENT"
      ) {
        setShowPrePaymentAmountForm(false);
        setPaymentUrl(PrepaymentData.url);
        setShowLinkForPrePayment(true);
      }
      setIsLoading(false);
    } catch (err) {
      console.log("error in makeLoanPrepaymentAPICall API", err);
    } finally {
    }
  };

  const makePrepaymentUpdateAPICall = async () => {
    try {
      const data = await loanStatusAPICall("prePaymentStatus");
      setIsLoading(false);
      setBankName(data[0].message.order.provider.descriptor.short_desc);
      setBankLogoUrl(data[0].message.order.provider.descriptor.images[0].url);
      setBankLogoUrl(data[0].message.order.provider.descriptor.images[0].url);
      setLoanDetails(data[0].message.order.items[0]);
      setLoanDisbursementDetails(data[0].message.order.quote);
      setCustomerDetails(data[0].message.order.fulfillments[0]);
      setPaymentDetails(data[0].message.order.payments);
      setCancellationDetails(data[0].message.order.cancellation_terms);
      setagreementDetails(data[0].message.order.documents[0]);
      if (
        data[0].message.order.payments[0].status === "PAID" &&
        data[0].message.order.payments[0].time.label === "PRE_PART_PAYMENT"
      ) {
        setShowLinkForPrePayment(false);
        setShowPrePaymentSuccessContent(true);
      }
    } catch (err) {
      console.log("error in makePrepaymentUpdateAPICall API", err);
    } finally {
    }
  };

  const navigateToEMIPayment = async (paymentUrl) => {
    setShowPrePaymentStatusBtn(true);
    window.open(paymentUrl);
  };

  function submitPrepaymentDetails(e) {
    setIsLoading(true);
    makeLoanPrepaymentAPICall();
  }

  const makeLowecase = (text) => {
    return (text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()).replace(
      /_/g,
      " "
    );
  };

  useEffect(() => {
    if (!sessionStorage.getItem("orderId")) {
      setLoanStatusModal(true);
    }
  }, []);

  return (
    <div>
      <Typography component="div" mt={2} ml={6}>
        <Link to="/application/home" className={classes.backToHome}>
          &#60; Back to Home
        </Link>
      </Typography>

      {loanStatusModal && (
        <ModalComponent
          open={loanStatusModal}
          onClose={() => {
            setLoanStatusModal(false);
            history.push("/application/home");
          }}
          title={"Loan Status"}
        >
          You don't have any active loan with us.
          <Link to="/application/home">Click</Link> to apply Loan.
        </ModalComponent>
      )}

      {showPrePaymentAmountForm && (
        <div className={styles.auth_form}>
          <Typography
            component="div"
            variant="h3"
            my={2}
            align="center"
            color="#196aab"
          >
            Loan Pre Payment
          </Typography>
          <form>
            <Grid
              container
              columnSpacing={10}
              mt={2}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={7}>
                <Input
                  id="loanAmount"
                  name="loanAmount"
                  type="number"
                  placeholder="Enter Pre Payment Amount"
                  label_name="Enter Pre Payment Amount"
                  autoComplete="off"
                  has_error={inlineError.loanAmount_error}
                  onChange={(event) => {
                    setLoanAmount(event.target.value);
                    setInlineError((inlineError) => ({
                      ...inlineError,
                      loanAmount_error: "",
                    }));
                  }}
                  onBlur={checkLoanAmount}
                  required
                />
                {inlineError.loanAmount_error && (
                  <ErrorMessage>{inlineError.loanAmount_error}</ErrorMessage>
                )}
              </Grid>
              <Grid item xs={12}>
                <div className="py-4 text-center">
                  <Button
                    isloading={isLoading ? 1 : 0}
                    disabled={isLoading}
                    button_type={buttonTypes.primary}
                    button_hover_type={buttonTypes.primary_hover}
                    type="button"
                    button_text="Submit"
                    onClick={submitPrepaymentDetails}
                  />
                </div>
              </Grid>
            </Grid>
          </form>
        </div>
      )}

      {showLinkForPrePayment && (
        <div className={styles.auth_form}>
          <Grid
            container
            spacing={0}
            className={classes.productItemContainerList}
            sx={{ width: "auto", height: "auto" }}
            mx={3}
            mb={5}
          >
            <Grid
              item
              xs={4}
              className={classes.bankInformationList}
              sx={{
                borderTopLeftRadius: "12px",
                borderBottomLeftRadius: "12px",
              }}
            >
              <Typography component="div" my={3} ml={3}>
                <img src={loan_Pre_Payment} height="200px" />
              </Typography>
            </Grid>
            <Grid
              item
              xs={8}
              pl={3}
              className={classes.bankInformationList}
              sx={{
                borderTopRightRadius: "12px",
                borderBottomRightRadius: "12px",
              }}
            >
              <Typography component="div" variant="h3" my={2} color="#196aab">
                Pre payment Details
              </Typography>
              {showPrePaymentStatusBtn ? (
                <div className="pb-4">
                  <Typography component="div" my={2} color="#196aab">
                    <span>
                      Thank you for making pre payment and please click
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        Check for status{" "}
                      </span>{" "}
                      button to know the payment status.
                    </span>
                  </Typography>
                  <Button
                    isloading={isLoading ? 1 : 0}
                    disabled={isLoading}
                    button_type={buttonTypes.primary}
                    button_hover_type={buttonTypes.primary_hover}
                    button_text="Check for status"
                    type="button"
                    onClick={() => {
                      setIsLoading(true);
                      makePrepaymentUpdateAPICall();
                    }}
                  />
                </div>
              ) : (
                <Fragment>
                  <Typography component="div" my={2} color="#196aab">
                    <span style={{ fontWeight: "bold" }}>Note :</span> Please
                    make pre payment by clicking below link.
                  </Typography>
                  <Typography component="div" my={2}>
                    <a
                      href="#"
                      rel="noreferrer"
                      target="_blank"
                      style={{ fontWeight: "bold" }}
                      onClick={() => {
                        navigateToEMIPayment(paymentUrl);
                      }}
                    >
                      Click to make Pre Payment
                    </a>
                  </Typography>
                </Fragment>
              )}
            </Grid>
          </Grid>
        </div>
      )}

      {showPrePaymentSuccessContent && (
        <Fragment>
          <Grid
            container
            spacing={0}
            className={classes.productItemContainerList}
            sx={{ width: "auto" }}
            mx={3}
            my={4}
          >
            <Grid
              item
              xs={12}
              className={classes.productDetailsTypo}
              sx={{
                borderRadius: "12px",
              }}
            >
              <Typography
                component="div"
                variant="h5"
                className={classes.headerTitle}
              >
                Current outstanding Principal & Interest Details
              </Typography>

              {loanDisbursementDetails.breakup.map((item, ind) => {
                return (
                  <Box style={{ display: "inline-block" }} key={ind}>
                    <Typography
                      variant="body"
                      style={{ display: "block", marginRight: "30px" }}
                      className={classes.descriptionItemList}
                    >
                      {makeLowecase(item.title)}
                    </Typography>
                    <Typography
                      variant="body"
                      style={{ display: "inline-block", fontWeight: "bold" }}
                      className={classes.descriptionItemList}
                    >
                      {item.price.value}
                    </Typography>
                  </Box>
                );
              })}
              <Box style={{ display: "inline-block" }}>
                <Typography
                  variant="body"
                  style={{ display: "block", marginRight: "30px" }}
                  className={classes.descriptionItemList}
                >
                  Total Amount to Pay with Interest
                </Typography>
                <Typography
                  variant="body"
                  style={{ display: "inline-block", fontWeight: "bold" }}
                  className={classes.descriptionItemList}
                >
                  {loanDisbursementDetails.price.value}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.productItemContainerList}
            sx={{ width: "auto" }}
            mx={3}
            mb={4}
          >
            <Grid
              item
              xs={12}
              sx={{
                borderRadius: "12px",
              }}
              className={classes.bankInformationList}
            >
              <Typography
                component="div"
                variant="h5"
                className={classes.headerTitle}
              >
                Loan Processing fee & Payment Details
              </Typography>

              {loanDetails.tags[0].list.map((item, ind) => {
                // if (
                //   item.descriptor.code !== "INSTALLMENT_AMOUNT" &&
                //   item.descriptor.code !== "COOL_OFF_PERIOD"
                // ) {
                return (
                  <Box
                    style={{ display: "inline-block", marginBottom: "20px" }}
                    key={ind}
                  >
                    <Typography
                      variant="body"
                      style={{ display: "block", marginRight: "30px" }}
                      className={classes.descriptionItemList}
                    >
                      {item.descriptor.name}
                    </Typography>
                    <Typography
                      variant="body"
                      style={{ display: "inline-block", fontWeight: "bold" }}
                      className={classes.descriptionItemList}
                    >
                      {item.descriptor.code === "TNC_LINK" ? (
                        <a href={item.value} rel="noreferrer" target="_blank">
                          Click here
                        </a>
                      ) : (
                        item.value
                      )}
                    </Typography>
                  </Box>
                );
                // }
              })}
            </Grid>
          </Grid>

          <Grid
            container
            spacing={0}
            className={classes.productItemContainerList}
            sx={{ width: "auto" }}
            mx={3}
            mb={4}
          >
            <Grid item xs={12}>
              <Typography
                component="div"
                variant="h4"
                my={2}
                align="center"
                color="#196aab"
              >
                Loan Repayment Details
              </Typography>
              <TableContainer
                component={Paper}
                className={classes.productDetailsTypo}
              >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600 }}>EMI No</TableCell>
                      <TableCell sx={{ fontWeight: 600 }} align="right">
                        EMI Amount
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600 }} align="right">
                        Start Date
                      </TableCell>
                      <TableCell sx={{ fontWeight: 600 }} align="right">
                        End Date
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paymentDetails.map((row, index) => {
                      if (
                        row.type === "POST_FULFILLMENT" &&
                        row.time.label === "INSTALLMENT"
                      ) {
                        return (
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {index}
                            </TableCell>
                            <TableCell align="right">
                              {row.params.amount}
                            </TableCell>
                            <TableCell align="right">
                              {converToDate(row.time.range.start)}
                            </TableCell>
                            <TableCell align="right">
                              {converToDate(row.time.range.end)}
                            </TableCell>
                          </TableRow>
                        );
                      }
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className="loanRepayMentSection"
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={paymentDetails.length - 1}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Grid>
          </Grid>
        </Fragment>
      )}
    </div>
  );
}

export default LoanPrepayment;
