import React, { useState, useEffect, Fragment } from "react";
import Box from "@mui/material/Box";
import formstyles from "../../../styles/auth/auth.module.scss";
import styles from "../../../styles/auth/auth.module.scss";
import { buttonTypes } from "../../shared/button/utils";
import Button from "../../shared/button/button";
import useStyles from "../style";
import { useHistory, Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ModalComponent from "../../common/Modal";
import Loading from "./../../shared/loading/loading";
import {
  loanEMIMissedDetails,
  loanStatusAPICall,
} from "../../../api/personal.loan.api";
import loan_EMI_Missed from "../../../assets/images/loan_EMI_Missed.jpg";

const LoanEMIMissedDetails = () => {
  const classes = useStyles();
  const history = useHistory();
  const [orderId, setOrderId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [loanStatusModal, setLoanStatusModal] = useState(false);
  const [emiStatusModal, setEmiStatusModal] = useState(false);
  const [showMissedEMIDetails, setShowMissedEMIDetails] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState();
  const [paymentAmount, setPaymentAmount] = useState();
  const [showMissedEMIStatusBtn, setShowMissedEMIStatusBtn] = useState(false);

  const getLoanEMIMissedDetails = async () => {
    try {
      const data = await loanEMIMissedDetails();
      const missedEMIDetails = data[0].message.order.payments[0];
      if (
        missedEMIDetails.status === "NOT-PAID" &&
        missedEMIDetails.time.label === "MISSED_EMI_PAYMENT"
      ) {
        setPaymentUrl(missedEMIDetails.url);
        setPaymentAmount(missedEMIDetails.params.amount);
        setShowMissedEMIDetails(true);
        setIsLoading(false);
      }
    } catch (err) {
      console.log("error in getLoanEMIMissedDetails API", err);
    } finally {
    }
  };

  const makeMissingEMIUpdateAPICall = async () => {
    setIsLoading(true);
    try {
      const data = await loanStatusAPICall();
      const missedEMIDetails = data[0].message.order.payments[0];
      if (
        missedEMIDetails.status === "PAID" &&
        missedEMIDetails.time.label === "MISSED_EMI_PAYMENT"
      ) {
        setIsLoading(false);
        setEmiStatusModal(true);
      }
    } catch (err) {
      console.log("error in makeMissingEMIUpdateAPICall API", err);
    } finally {
    }
  };

  const navigateToMissingEMIPayment = async (url) => {
    setShowMissedEMIStatusBtn(true);
    window.open(url);
  };

  useEffect(() => {
    if (sessionStorage.getItem("orderId")) {
      setIsLoading(true);
      setOrderId(sessionStorage.getItem("orderId"));
      getLoanEMIMissedDetails();
    } else {
      setLoanStatusModal(true);
    }
  }, []);

  return (
    <React.Fragment>
      {loanStatusModal && (
        <ModalComponent
          open={loanStatusModal}
          onClose={() => {
            setLoanStatusModal(false);
            history.push("/application/home");
          }}
          title={"Loan Status"}
        >
          You don't have any active loan with us.
          <Link to="/application/home">Click</Link> to apply Loan.
        </ModalComponent>
      )}

      {emiStatusModal && (
        <ModalComponent
          open={emiStatusModal}
          onClose={() => {
            setLoanStatusModal(false);
            history.push("/application/home");
          }}
          title={"Missing EMI Status"}
        >
          You have completed all your missing EMI payments.
          <Link to="/application/home"> Click</Link> to navigate to Home page.
        </ModalComponent>
      )}
      {isLoading && orderId ? (
        <Loading />
      ) : (
        <Fragment>
          <Typography component="div" mt={3} ml={4}>
            <Link
              to="/application/home"
              style={{
                textDecoration: "none",
                color: "#196aab",
                fontWeight: "bold",
              }}
              className={classes.navigation_link}
            >
              &#60;- Back to Home
            </Link>
          </Typography>
          {showMissedEMIDetails && (
            <div className={styles.auth_form}>
              <Grid
                container
                spacing={0}
                className={classes.productItemContainerList}
                sx={{ width: "auto", height: "auto" }}
                mx={3}
                mb={5}
              >
                <Grid
                  item
                  xs={4}
                  className={classes.bankInformationList}
                  sx={{
                    borderTopLeftRadius: "12px",
                    borderBottomLeftRadius: "12px",
                  }}
                >
                  <Typography component="div" my={3} ml={3}>
                    <img src={loan_EMI_Missed} height="200px" />
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={8}
                  pl={3}
                  className={classes.bankInformationList}
                  sx={{
                    borderTopRightRadius: "12px",
                    borderBottomRightRadius: "12px",
                  }}
                >
                  <Typography
                    component="div"
                    variant="h3"
                    my={2}
                    color="#196aab"
                  >
                    Missing EMI Details
                  </Typography>
                  {showMissedEMIStatusBtn ? (
                    <div className="pb-4">
                      <Typography component="div" my={2} color="#196aab">
                        <span>
                          Thank you for making payment and please click
                          <span style={{ fontWeight: "bold" }}>
                            {" "}
                            Check for Status{" "}
                          </span>{" "}
                          button to know the payment status.
                        </span>
                      </Typography>
                      <Button
                        isloading={isLoading ? 1 : 0}
                        disabled={isLoading}
                        button_type={buttonTypes.primary}
                        button_hover_type={buttonTypes.primary_hover}
                        button_text="Check for Status"
                        type="button"
                        onClick={() => {
                          setIsLoading(true);
                          makeMissingEMIUpdateAPICall();
                        }}
                      />
                    </div>
                  ) : (
                    <Fragment>
                      <Box style={{ display: "block" }}>
                        <Typography
                          component="div"
                          className={classes.descriptionItemList}
                        >
                          Missing EMI amount to Pay:
                        </Typography>
                        <Typography
                          pl={1}
                          component="div"
                          style={{ fontWeight: "bold" }}
                          className={classes.descriptionItemList}
                        >
                          {paymentAmount}
                        </Typography>
                      </Box>
                      <Typography component="div" my={2} color="#196aab">
                        <span style={{ fontWeight: "bold" }}>Note :</span>{" "}
                        Please make payment by clicking below link.
                      </Typography>
                      <Typography component="div" my={2}>
                        <a
                          href={paymentUrl}
                          rel="noreferrer"
                          target="_blank"
                          style={{ fontWeight: "bold" }}
                          onClick={() => {
                            navigateToMissingEMIPayment(paymentUrl);
                          }}
                        >
                          Click here to make Pre payment
                        </a>
                      </Typography>
                    </Fragment>
                  )}
                </Grid>
              </Grid>
            </div>
          )}
        </Fragment>
      )}
    </React.Fragment>
  );
};
export default LoanEMIMissedDetails;
