import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import formstyles from "../../../styles/auth/auth.module.scss";
import useStyles from "../style";
import { useHistory, Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ModalComponent from "../../common/Modal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Paper from "@mui/material/Paper";
import Loading from "./../../shared/loading/loading";
import { loanStatusAPICall } from "../../../api/personal.loan.api";
import loan_Status from "../../../assets/images/loan_Status.png";

const LoanStatus = () => {
  const classes = useStyles();
  const history = useHistory();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [loanDetails, setLoanDetails] = useState();
  const [loanDisbursementDetails, setLoanDisbursementDetails] = useState();
  const [bankName, setBankName] = useState();
  const [customerDetails, setCustomerDetails] = useState();
  const [paymentDetails, setPaymentDetails] = useState();
  const [cancellationDetails, setCancellationDetails] = useState();
  const [agreementDetails, setagreementDetails] = useState();
  const [orderId, setOrderId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loanStatusModal, setLoanStatusModal] = useState(false);

  const getLoanStatusDetails = async () => {
    try {
      const data = await loanStatusAPICall();
      setBankName(data[0].message.order.provider.descriptor.short_desc);
      setLoanDetails(data[0].message.order.items[0]);
      setLoanDisbursementDetails(data[0].message.order.quote);
      setCustomerDetails(data[0].message.order.fulfillments[0]);
      setPaymentDetails(data[0].message.order.payments);
      setCancellationDetails(data[0].message.order.cancellation_terms);
      setagreementDetails(data[0].message.order.documents[0]);
      setIsLoading(false);
    } catch (err) {
      console.log("error in getStatusDetails API", err);
    } finally {
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const makeLowecase = (text) => {
    return (text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()).replace(
      /_/g,
      " "
    );
  };

  const converToDate = (date) => {
    return new Date(date).toISOString().substring(0, 10);
  };

  useEffect(() => {
    if (sessionStorage.getItem("orderId")) {
      setIsLoading(true);
      setOrderId(sessionStorage.getItem("orderId"));
      getLoanStatusDetails();
    } else {
      setLoanStatusModal(true);
    }
  }, []);

  return (
    <React.Fragment>
      {loanStatusModal && (
        <ModalComponent
          open={loanStatusModal}
          onClose={() => {
            setLoanStatusModal(false);
            history.push("/application/home");
          }}
          title={"Loan Status"}
        >
          You did not apply any loan and please{" "}
          <Link to="/application/home">Click</Link> to apply Loan.
        </ModalComponent>
      )}
      {isLoading && orderId ? (
        <Loading />
      ) : (
        <React.Fragment>
          {bankName && (
            <React.Fragment>
              <Typography component="div" mt={3} ml={4}>
                <Link
                  to="/application/home"
                  style={{
                    textDecoration: "none",
                    color: "#196aab",
                    fontWeight: "bold",
                  }}
                  className={classes.navigation_link}
                >
                  &#60;- Back to Home
                </Link>
              </Typography>
              <div className={formstyles.auth_form}>
                <Grid
                  container
                  spacing={0}
                  sx={{ width: "auto", height: "auto" }}
                  mx={3}
                  mb={5}
                >
                  <Grid item xs={6}>
                    <Typography component="div" my={3} ml={3}>
                      <img src={loan_Status} height="200px" />
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                      component="div"
                      variant="h3"
                      mt={4}
                      color="#196aab"
                    >
                      Loan Status
                    </Typography>
                    <div className="pb-4">
                      <Typography
                        component="div"
                        my={2}
                        color="#196aab"
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        Your loan got {customerDetails.state.descriptor.code} &
                        you can check your Loan Details below.
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <Grid
                container
                spacing={0}
                className={classes.productItemContainerList}
                sx={{ width: "auto" }}
                mx={3}
                mb={4}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  sx={{
                    borderTopLeftRadius: "12px",
                    borderBottomLeftRadius: "12px",
                  }}
                  className={classes.bankInformationList}
                >
                  <Typography
                    component="div"
                    variant="h5"
                    className={classes.headerTitle}
                  >
                    Customer Details
                  </Typography>
                  <Box style={{ display: "block" }}>
                    <Typography
                      variant="body"
                      className={classes.descriptionItemList}
                    >
                      Name :
                    </Typography>
                    <Typography
                      pl={1}
                      variant="body"
                      style={{ fontWeight: "bold" }}
                      className={classes.descriptionItemList}
                    >
                      {customerDetails.customer.person.name}
                    </Typography>
                  </Box>
                  <Box style={{ display: "block" }}>
                    <Typography
                      variant="body"
                      style={{ display: "inline-block" }}
                      className={classes.descriptionItemList}
                    >
                      Phone Number :
                    </Typography>
                    <Typography
                      pl={1}
                      variant="body"
                      style={{ fontWeight: "bold" }}
                      className={classes.descriptionItemList}
                    >
                      {customerDetails.customer.contact.phone}
                    </Typography>
                  </Box>
                  <Box style={{ display: "block" }}>
                    <Typography
                      variant="body"
                      className={classes.descriptionItemList}
                    >
                      Email :
                    </Typography>
                    <Typography
                      pl={1}
                      variant="body"
                      style={{ fontWeight: "bold" }}
                      className={classes.descriptionItemList}
                    >
                      {customerDetails.customer.contact.email}
                    </Typography>
                  </Box>
                  <Box style={{ display: "block" }}>
                    <Typography
                      variant="body"
                      className={classes.descriptionItemList}
                    >
                      Loan Status :
                    </Typography>
                    <Typography
                      pl={1}
                      variant="body"
                      style={{ fontWeight: "bold" }}
                      className={classes.descriptionItemList}
                    >
                      {customerDetails.state.descriptor.code}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  className={classes.bankInformationList}
                >
                  <Typography
                    component="div"
                    variant="h5"
                    className={classes.headerTitle}
                  >
                    Selected Bank
                  </Typography>
                  <Box style={{ display: "block" }}>
                    <Typography
                      variant="body"
                      className={classes.descriptionItemList}
                    >
                      Bank Name :
                    </Typography>
                    <Typography
                      pl={1}
                      variant="body"
                      style={{ fontWeight: "bold" }}
                      className={classes.descriptionItemList}
                    >
                      {bankName}
                    </Typography>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={3}
                  className={classes.bankInformationList}
                >
                  <Typography
                    component="div"
                    variant="h5"
                    className={classes.headerTitle}
                  >
                    Cancellation % Charges
                  </Typography>

                  {cancellationDetails.map((item, ind) => {
                    if (item?.fulfillment_state) {
                      return (
                        <Box style={{ display: "block" }}>
                          <Typography
                            variant="body"
                            className={classes.descriptionItemList}
                          >
                            {item.fulfillment_state.descriptor.code} :
                          </Typography>
                          <Typography
                            pl={1}
                            variant="body"
                            style={{ fontWeight: "bold" }}
                            className={classes.descriptionItemList}
                          >
                            {item.cancellation_fee.percentage}
                          </Typography>
                        </Box>
                      );
                    }
                  })}
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={3}
                  className={classes.bankInformationList}
                  sx={{
                    borderTopRightRadius: "12px",
                    borderBottomRightRadius: "12px",
                  }}
                >
                  <Typography
                    component="div"
                    variant="h5"
                    className={classes.headerTitle}
                  >
                    Loan Agreement
                  </Typography>
                  <Box style={{ display: "block" }}>
                    <Typography
                      variant="body"
                      //style={{ display: "inline-block" }}
                      className={classes.descriptionItemList}
                    >
                      {agreementDetails.descriptor.name}
                    </Typography>
                    <Typography
                      pl={1}
                      variant="body"
                      style={{ display: "inline-block" }}
                      className={classes.descriptionItemList}
                    >
                      <a
                        href={agreementDetails.url}
                        rel="noreferrer"
                        target="_blank"
                      >
                        Download from here
                      </a>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={0}
                className={classes.productItemContainerList}
                sx={{ width: "auto" }}
                mx={3}
                mb={4}
              >
                <Grid
                  item
                  xs={12}
                  //md={6}
                  className={classes.productDetailsTypo}
                  sx={{
                    borderRadius: "12px",
                  }}
                >
                  <Typography
                    component="div"
                    variant="h5"
                    className={classes.headerTitle}
                  >
                    Loan Disbursement Details
                  </Typography>

                  {loanDisbursementDetails.breakup.map((item, ind) => {
                    return (
                      <Box style={{ display: "inline-block" }} key={ind}>
                        <Typography
                          variant="body"
                          style={{ display: "block", marginRight: "30px" }}
                          className={classes.descriptionItemList}
                        >
                          {makeLowecase(item.title)}
                        </Typography>
                        <Typography
                          variant="body"
                          style={{
                            display: "inline-block",
                            fontWeight: "bold",
                          }}
                          className={classes.descriptionItemList}
                        >
                          {item.price.value}
                        </Typography>
                      </Box>
                    );
                  })}
                  <Box style={{ display: "inline-block" }}>
                    <Typography
                      variant="body"
                      style={{ display: "block", marginRight: "30px" }}
                      className={classes.descriptionItemList}
                    >
                      Total Amount to Pay with Interest
                    </Typography>
                    <Typography
                      variant="body"
                      style={{ display: "inline-block", fontWeight: "bold" }}
                      className={classes.descriptionItemList}
                    >
                      {loanDisbursementDetails.price.value}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={0}
                className={classes.productItemContainerList}
                sx={{ width: "auto" }}
                mx={3}
                mb={4}
              >
                <Grid
                  item
                  xs={12}
                  // sm={12}
                  // md={6}
                  sx={{
                    borderRadius: "12px",
                  }}
                  className={classes.bankInformationList}
                >
                  <Typography
                    component="div"
                    variant="h5"
                    className={classes.headerTitle}
                  >
                    Loan Processing fee & Payment Details
                  </Typography>

                  {loanDetails.tags[0].list.map((item, ind) => {
                    // if (
                    //   item.descriptor.code !== "INSTALLMENT_AMOUNT" &&
                    //   item.descriptor.code !== "COOL_OFF_PERIOD"
                    // ) {
                    return (
                      <Box
                        style={{
                          display: "inline-block",
                          marginBottom: "20px",
                        }}
                        key={ind}
                      >
                        <Typography
                          variant="body"
                          style={{ display: "block", marginRight: "30px" }}
                          className={classes.descriptionItemList}
                        >
                          {item.descriptor.name}
                        </Typography>
                        <Typography
                          variant="body"
                          style={{
                            display: "inline-block",
                            fontWeight: "bold",
                          }}
                          className={classes.descriptionItemList}
                        >
                          {item.descriptor.code === "TNC_LINK" ? (
                            <a
                              href={item.value}
                              rel="noreferrer"
                              target="_blank"
                            >
                              Click here
                            </a>
                          ) : (
                            item.value
                          )}
                        </Typography>
                      </Box>
                    );
                    // }
                  })}
                </Grid>
              </Grid>

              <Grid
                container
                spacing={0}
                className={classes.productItemContainerList}
                sx={{ width: "auto" }}
                mx={3}
                mb={4}
              >
                <Grid
                  item
                  xs={12}
                  //className={classes.bankInformationList}
                >
                  <Typography
                    component="div"
                    variant="h4"
                    my={2}
                    align="center"
                    color="#196aab"
                    //className={classes.bankInformationList}
                  >
                    Loan Repayment Details
                  </Typography>
                  <TableContainer
                    component={Paper}
                    className={classes.productDetailsTypo}
                  >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: 600 }}>EMI No</TableCell>
                          <TableCell sx={{ fontWeight: 600 }} align="right">
                            EMI Amount
                          </TableCell>
                          <TableCell sx={{ fontWeight: 600 }} align="right">
                            Start Date
                          </TableCell>
                          <TableCell sx={{ fontWeight: 600 }} align="right">
                            End Date
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {paymentDetails.map((row, index) => {
                          if (row.type === "POST_FULFILLMENT") {
                            return (
                              <TableRow
                                //key={row.time.label}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {index}
                                </TableCell>
                                <TableCell align="right">
                                  {row.params.amount}
                                </TableCell>
                                <TableCell align="right">
                                  {converToDate(row.time.range.start)}
                                </TableCell>
                                <TableCell align="right">
                                  {converToDate(row.time.range.end)}
                                </TableCell>
                              </TableRow>
                            );
                          }
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    className="loanRepayMentSection"
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={paymentDetails.length - 1}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Grid>
              </Grid>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
export default LoanStatus;
