import React, { useState, useEffect, Fragment } from "react";
import Box from "@mui/material/Box";
import styles from "../../../styles/auth/auth.module.scss";
import { buttonTypes } from "../../shared/button/utils";
import Button from "../../shared/button/button";
import useStyles from "../style";
import { useHistory, Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ModalComponent from "../../common/Modal";
import Loading from "./../../shared/loading/loading";
import {
  loanForeClosureAPICall,
  loanStatusAPICall,
} from "../../../api/personal.loan.api";
import loan_Fore_Closure from "../../../assets/images/loan_Fore_Closure.jpg";

const LoanForeClosure = () => {
  const classes = useStyles();
  const history = useHistory();
  const [orderId, setOrderId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [loanStatusModal, setLoanStatusModal] = useState(false);
  const [foreClosureStatusModal, setForeClosureStatusModal] = useState(false);
  const [showLoanForeClosureDetails, setShowLoanForeClosureDetails] =
    useState(true);
  const [showForeClosureStatusBtn, setShowForeClosureStatusBtn] =
    useState(false);

  const [paymentUrl, setPaymentUrl] = useState();
  const [paymentAmount, setPaymentAmount] = useState();

  const getLoanForeClosureDetails = async () => {
    try {
      const data = await loanForeClosureAPICall();
      const loanForeClosureData = data[0].message.order.payments[0];
      if (
        loanForeClosureData.status === "NOT-PAID" &&
        loanForeClosureData.time.label === "FORECLOSURE"
      ) {
        setIsLoading(false);
        setPaymentUrl(loanForeClosureData.url);
        setPaymentAmount(loanForeClosureData.params.amount);
        setShowLoanForeClosureDetails(true);
      }
    } catch (err) {
      console.log("error in getLoanForeClosureDetails API", err);
    } finally {
    }
  };

  const makeForeClosureUpdateAPICall = async () => {
    try {
      const data = await loanStatusAPICall();
      setIsLoading(false);
      const foreClosureData = data[0].message.order.payments[0];
      if (
        foreClosureData.status === "PAID" &&
        foreClosureData.time.label === "FORECLOSURE"
      ) {
        setForeClosureStatusModal(true);
      }
    } catch (err) {
      console.log("error in checkForeClosureStatusAPICall API", err);
    } finally {
    }
  };

  const navigateToPayment = (paymentUrl) => {
    setShowForeClosureStatusBtn(true);
    window.open(paymentUrl);
  };

  useEffect(() => {
    if (sessionStorage.getItem("orderId")) {
      setIsLoading(true);
      setOrderId(sessionStorage.getItem("orderId"));
      getLoanForeClosureDetails();
    } else {
      setLoanStatusModal(true);
    }
  }, []);

  return (
    <React.Fragment>
      {loanStatusModal && (
        <ModalComponent
          open={loanStatusModal}
          onClose={() => {
            setLoanStatusModal(false);
            history.push("/application/home");
          }}
          title={"Loan Status"}
        >
          You don't have any active loan with us.
          <Link to="/application/home">Click</Link> to apply Loan.
        </ModalComponent>
      )}
      {foreClosureStatusModal && (
        <ModalComponent
          open={foreClosureStatusModal}
          onClose={() => {
            setForeClosureStatusModal(false);
            history.push("/application/home");
          }}
          title={"Fore Closure Loan Status"}
        >
          You have completed your loan and
          <Link to="/application/home"> Click</Link> to navigate to Home page.
        </ModalComponent>
      )}
      {isLoading && orderId ? (
        <Loading />
      ) : (
        <React.Fragment>
          <Typography component="div" mt={3} ml={4}>
            <Link
              to="/application/home"
              style={{
                textDecoration: "none",
                color: "#196aab",
                fontWeight: "bold",
              }}
              className={classes.navigation_link}
            >
              &#60;- Back to Home
            </Link>
          </Typography>
          {showLoanForeClosureDetails && (
            <div className={styles.auth_form}>
              <Grid
                container
                spacing={0}
                className={classes.productItemContainerList}
                sx={{ width: "auto", height: "auto" }}
                mx={3}
                mb={5}
              >
                <Grid
                  item
                  xs={4}
                  className={classes.bankInformationList}
                  sx={{
                    borderTopLeftRadius: "12px",
                    borderBottomLeftRadius: "12px",
                  }}
                >
                  <Typography component="div" my={3} ml={3}>
                    <img src={loan_Fore_Closure} height="200px" />
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={8}
                  pl={3}
                  className={classes.bankInformationList}
                  sx={{
                    borderTopRightRadius: "12px",
                    borderBottomRightRadius: "12px",
                  }}
                >
                  <Typography
                    component="div"
                    variant="h3"
                    my={2}
                    color="#196aab"
                  >
                    Loan Fore Closure Details
                  </Typography>
                  {showForeClosureStatusBtn ? (
                    <div className="pb-4">
                      <Typography component="div" my={2} color="#196aab">
                        <span>
                          Thank you for making payment and please click
                          <span style={{ fontWeight: "bold" }}>
                            {" "}
                            Check for Status{" "}
                          </span>{" "}
                          button to know the payment status.
                        </span>
                      </Typography>
                      <Button
                        isloading={isLoading ? 1 : 0}
                        disabled={isLoading}
                        button_type={buttonTypes.primary}
                        button_hover_type={buttonTypes.primary_hover}
                        button_text="Check for Status"
                        type="button"
                        onClick={() => {
                          setIsLoading(true);
                          makeForeClosureUpdateAPICall();
                        }}
                      />
                    </div>
                  ) : (
                    <Fragment>
                      <Box style={{ display: "block" }}>
                        <Typography
                          component="div"
                          className={classes.descriptionItemList}
                          color="#196aab"
                        >
                          Current Out standing amount to pay :
                        </Typography>
                        <Typography
                          pl={1}
                          component="div"
                          style={{ fontWeight: "bold" }}
                          className={classes.descriptionItemList}
                          color="#196aab"
                        >
                          {paymentAmount}
                        </Typography>
                      </Box>
                      <Typography component="div" my={2} color="#196aab">
                        <span style={{ fontWeight: "bold" }}>Note :</span>{" "}
                        Please make for closure payment by clicking below link.
                      </Typography>
                      <Typography component="div" my={2}>
                        <a
                          href={paymentUrl}
                          rel="noreferrer"
                          target="_blank"
                          style={{ fontWeight: "bold" }}
                          onClick={() => {
                            navigateToPayment(paymentUrl);
                          }}
                        >
                          Click to make ForeClosure Payment
                        </a>
                      </Typography>
                    </Fragment>
                  )}
                </Grid>
              </Grid>
            </div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
export default LoanForeClosure;
