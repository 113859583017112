import React, { useState, useEffect, Fragment } from "react";
import useStyles from "../style";
import { buttonTypes } from "../../shared/button/utils";
import { useHistory, Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import formstyles from "../../../styles/auth/auth.module.scss";
import loanEligibility from "../../../assets/images/loan_Eligibility_new.jpg";
import Button from "../../shared/button/button";
import Box from "@mui/material/Box";
import ModalComponent from "../../common/Modal";

const LoanEligibilityDetails = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const [loanDetails, setLoanDetails] = useState();
  const [loanDisbursementDetails, setLoanDisbursementDetails] = useState();
  const [bankName, setBankName] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [errorModal, setErrorModal] = useState(false);

  const [bankLogoUrl, setBankLogoUrl] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const [loanOffersData, setLoanOffersData] = useState([]);

  const getLoanEligibilityDetails = () => {
    setIsLoading(true);
    try {
      const data = JSON.parse(sessionStorage.getItem("loanOffersDetails"));
      console.log("data length==", data.length);
      if (data[0]?.error) {
        setErrorMessage(data[0].error.message);
        setErrorModal(true);
      } else {
        setLoanOffersData(data);
        // setBankName(data[0].message.order.provider.descriptor.short_desc);
        // setBankLogoUrl(data[0].message.order.provider.descriptor.images[0].url);
        // setLoanDetails(data[0].message.order.items[0]);
        // setLoanDisbursementDetails(data[0].message.order.quote);
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const makeLowecase = (text) => {
    return (text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()).replace(
      /_/g,
      " "
    );
  };

  useEffect(() => {
    getLoanEligibilityDetails();
    setTimeout(function () {
      document
        .getElementById("loanEligibilityDetailsContent")
        ?.scrollIntoView({ behavior: "instant", block: "start" });
    }, 200);
  }, []);

  return (
    <div
      id="loanEligibilityDetailsContent"
      style={{ scrollMarginTop: "100px" }}
    >
      {errorModal && (
        <ModalComponent
          open={errorModal}
          onClose={() => {
            setErrorModal(false);
            history.push("/application/home");
          }}
          title={"Error !"}
        >
          <div className={classes.colorBlue}>{errorMessage}</div>

          <Typography component="div" my={2} align="center" color="#196aab">
            <Button
              button_type={buttonTypes.primary}
              button_text="Try with other Bank"
              button_hover_type={buttonTypes.primary_hover}
              onClick={(e) => {
                history.push(`/application/home`);
              }}
            >
              Try Again
            </Button>
          </Typography>
        </ModalComponent>
      )}
      {loanOffersData.length && (
        <div>
          <Typography component="div" mt={2} ml={4}>
            <Link to="/application/home" className={classes.backToHome}>
              &#60;- Back to Home
            </Link>
          </Typography>
          <div className={formstyles.auth_form}>
            <Grid
              container
              spacing={0}
              sx={{ width: "auto", height: "auto" }}
              mx={3}
              mb={5}
            >
              <Grid item xs={4}>
                <Typography component="div" my={3} ml={3}>
                  <img src={loanEligibility} height="200px" />
                </Typography>
              </Grid>
              <Grid item xs={7}>
                {/* <Typography component="div" align="center" mt={2}>
                  <img
                    src={bankLogoUrl}
                    className={classes.brandImage}
                    alt="brand-image"
                  />
                </Typography> */}
                <Typography
                  component="div"
                  align="center"
                  variant="h4"
                  mt={14}
                  ml={2}
                  color="#196aab"
                >
                  Your Loan Eligibility & Offers Details
                </Typography>
              </Grid>
            </Grid>
          </div>
          <Grid
            container
            spacing={0}
            className={classes.productItemContainerList}
            sx={{ width: "auto", height: "auto" }}
            mx={3}
            mb={5}
            style={{ border: "none" }}
          >
            {loanOffersData.map((bankItem, ind) => (
              //<div>
              <Grid
                item
                xs={12}
                mb={5}
                className={classes.bankInformationList}
                sx={{
                  borderRadius: "12px",
                }}
              >
                <Typography
                  component="div"
                  variant="h5"
                  className={classes.headerTitle}
                >
                  {bankItem[0].message.order.provider.descriptor.short_desc}
                </Typography>
                <div className={classes.footerActions} style={{ top: "20px" }}>
                  <Button
                    //className={classes.applyBtn}
                    style={{ float: "right" }}
                    variant="outlined"
                    button_text="Click to Apply"
                    onClick={(e) => {
                      sessionStorage.setItem(
                        "selectedBankDetails",
                        JSON.stringify(bankItem[0])
                      );
                      history.push(`/application/loanDetails`);
                    }}
                  >
                    Click to Apply
                  </Button>
                </div>

                {bankItem[0].message.order.quote.breakup.map((item, ind) => (
                  <Box style={{ display: "inline-block" }} key={ind}>
                    <Typography
                      variant="body"
                      style={{ display: "block", marginRight: "30px" }}
                      className={classes.descriptionItemList}
                    >
                      {makeLowecase(item.title)}
                    </Typography>
                    <Typography
                      variant="body"
                      style={{
                        display: "block",
                        fontWeight: "bold",
                      }}
                      className={classes.descriptionItemList}
                    >
                      {item.price.value}
                    </Typography>
                  </Box>
                ))}

                {bankItem[0].message.order.items[0].tags[0].list.map(
                  (item, ind) => (
                    <Box
                      style={{
                        display: "inline-block",
                        marginBottom: "20px",
                      }}
                      key={ind}
                    >
                      <Typography
                        variant="body"
                        style={{ display: "block", marginRight: "30px" }}
                        className={classes.descriptionItemList}
                      >
                        {item.descriptor.name}
                      </Typography>
                      <Typography
                        pl={1}
                        variant="body"
                        style={{
                          display: "block",
                          fontWeight: "bold",
                        }}
                        className={classes.descriptionItemList}
                      >
                        {item.descriptor.code === "TNC_LINK" ? (
                          <a href={item.value} rel="noreferrer" target="_blank">
                            Click here
                          </a>
                        ) : (
                          item.value
                        )}
                      </Typography>
                    </Box>
                  )
                )}
              </Grid>
              //</div>
            ))}
          </Grid>
        </div>
      )}
    </div>
  );
};
export default LoanEligibilityDetails;
