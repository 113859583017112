import React, { useState, useEffect, useRef } from "react";
import LoanSearch from "../LoanSearch/LoanSearch";
import Grid from "@mui/material/Grid";
import { MenuItem, FormControl, Select } from "@mui/material";
import formStyles from "./../../shared/input/input.module.scss";
import useStyles from "./styles";
import { useHistory } from "react-router-dom";
import BtnStyles from "../style";
import { IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import { ReactComponent as PreviousIcon } from "../../../assets/images/previous.svg";
import { ReactComponent as NextIcon } from "../../../assets/images/next.svg";
import useCancellablePromise from "../../../api/cancelRequest";
import { searchAPICall } from "../../../api/personal.loan.api";
import loan from "../../../assets/images/loan.svg";
import Typography from "@mui/material/Typography";
import Loading from "./../../shared/loading/loading";

const LoanHome = () => {
  const classes = useStyles();
  const history = useHistory();
  const btnStyles = BtnStyles();
  const ref = useRef(null);
  const [bankInfo, setBankInfo] = useState();
  const [bankName, setBankName] = useState();
  const [bankList, setBankList] = useState([]);
  const [selectedBankDetails, setSelectedBankDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const getBankList = (data) => {
    let bankListArray = [];
    data.forEach((item) => {
      bankListArray.push({
        bankName: item.message.catalog.providers[0].descriptor.short_desc,
        imageUrl: item.message.catalog.providers[0].descriptor.images[0].url,
      });
    });

    return bankListArray;
  };

  const selectedBankDetailsArray = (bankName) => {
    const selectdeBank = bankInfo.find((item, index) => {
      return (
        bankName === item.message.catalog.providers[0].descriptor.short_desc
      );
    });
    setSelectedBankDetails(selectdeBank);
  };

  const scroll = (scrollOffset) => {
    ref.current.scrollLeft += scrollOffset;
  };

  const makeSearchAPICall = async () => {
    setIsLoading(true);
    try {
      const data = await searchAPICall();
      const personalLoanData = data.filter(
        (item) =>
          item.message.catalog.providers[0].categories[0].descriptor.code ===
          "PERSONAL_LOAN"
      );
      setBankInfo(personalLoanData);
      setSelectedBankDetails(personalLoanData[0]);
      setBankList(getBankList(personalLoanData));
      setBankName(
        personalLoanData[0].message.catalog.providers[0].descriptor.short_desc
      );
      setIsLoading(false);
      sessionStorage.setItem(
        "trasactionId",
        personalLoanData[0].context.transaction_id
      );
    } catch (err) {
      console.log("error in makeSearchAPICall", err);
    } finally {
    }
  };

  useEffect(() => {
    makeSearchAPICall();
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        <Loading />
      ) : (
        <React.Fragment>
          <Typography
            component="div"
            variant="h3"
            my={3}
            align="center"
            color="#196aab"
          >
            Our Trusted Partners
          </Typography>
          {bankList.length && (
            <div className={classes.bankCarousel}>
              <div className={classes.leftIcon}>
                <IconButton
                  color="inherit"
                  className={classes.actionButton}
                  onClick={() => {
                    scroll(-1000);
                  }}
                >
                  <PreviousIcon />
                </IconButton>
              </div>
              <div className={classes.rightIcon}>
                <IconButton
                  color="inherit"
                  className={classes.actionButton}
                  onClick={() => {
                    scroll(1000);
                  }}
                >
                  <NextIcon style={{ fontSize: 30 }} />
                </IconButton>
              </div>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={classes.bankList}
                  ref={ref}
                >
                  {bankList.map((bank, ind) => {
                    return (
                      <div className={classes.bankCard} key={ind}>
                        <div className={classes.left}>
                          <p className={classes.bankText}>{bank.bankName}</p>

                          <img
                            src={bank.imageUrl}
                            className={classes.brandImage}
                            alt="brand-image"
                          />
                        </div>
                      </div>
                    );
                  })}
                </Grid>
              </Grid>
            </div>
          )}
          {bankList.length && (
            <Grid
              container
              mb={3}
              justifyContent="center"
              alignItems="center"
              columnSpacing={10}
            >
              <Grid item xs={12} md={5} ml={15}>
                <FormControl style={{ width: "100%" }}>
                  <label
                    id="Gender"
                    className={`${formStyles.form_label} ${formStyles.required}`}
                  >
                    Select Bank to Know More Details
                  </label>
                  <Select
                    style={{ height: "45px" }}
                    required
                    size="small"
                    labelId="bank"
                    id="bank-select"
                    value={bankName}
                    label="Select Bank"
                    onChange={(e) => {
                      selectedBankDetailsArray(e.target.value);
                      setBankName(e.target.value);
                    }}
                  >
                    {bankList.map((item, ind) => {
                      return (
                        <MenuItem value={item.bankName} key={ind}>
                          {item.bankName}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={4}>
                <img src={loan} height={200} width={200} />
              </Grid>
            </Grid>
          )}
          <Typography
            component="div"
            //variant="h3"
            my={3}
            align="center"
          >
            <Button
              variant="outlined"
              onClick={(e) => {
                history.push(`/application/customerDetails`);
              }}
            >
              Check Eligibility
            </Button>
          </Typography>

          {selectedBankDetails && (
            <LoanSearch selectedBankInfo={selectedBankDetails} />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default LoanHome;
