import React, { Fragment } from "react";
import { ONDC_COLORS } from "../colors";
import { Typography } from "@mui/material";
import styles from "./loading.module.scss";

export default function Loading(props) {
  const {
    backgroundColor = ONDC_COLORS.ACCENTCOLOR,
    width,
    height,
    loadingText,
  } = props;
  return (
    <Fragment>
      <div className={styles.wrapper} style={{ marginTop: "150px" }}>
        <div
          className={styles.dot1}
          style={{
            backgroundColor,
            width: width ? width : "50px",
            height: height ? height : "50px",
          }}
        ></div>
        <div
          className={styles.dot2}
          style={{
            backgroundColor,
            width: width ? width : "50px",
            height: height ? height : "50px",
          }}
        ></div>
        <div
          className={styles.dot3}
          style={{
            backgroundColor,
            width: width ? width : "50px",
            height: height ? height : "50px",
          }}
        ></div>
        <div
          className={styles.dot4}
          style={{
            backgroundColor,
            width: width ? width : "50px",
            height: height ? height : "50px",
          }}
        ></div>
      </div>
      {loadingText && (
        <Typography
          component="div"
          variant="h5"
          my={2}
          color="#196aab"
          align="center"
        >
          {loadingText}
        </Typography>
      )}
    </Fragment>
  );
}
