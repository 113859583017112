import React, { useState, useEffect } from "react";
import { buttonTypes } from "../../shared/button/utils";
import Button from "../../shared/button/button";
import { Link, useHistory } from "react-router-dom";
import styles from "../../../styles/auth/auth.module.scss";
import useStyles from "../style";
import { Typography } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid";
import { selectApproveAPICall } from "../../../api/personal.loan.api";
import Loading from "./../../shared/loading/loading";
import kyc from "../../../assets/images/kyc.png";

function FinVuPage() {
  const history = useHistory();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const makeSelectApproveAPICall = async () => {
    setIsLoading(true);
    try {
      const data = await selectApproveAPICall();
      const personalLoanData = data.filter((item) => !item[0].error);
      sessionStorage.setItem(
        "loanOffersDetails",
        JSON.stringify(personalLoanData)
      );
      history.push("/application/loanEligibilityDetails");
    } catch (err) {
      console.log("error in makeSelectApproveAPICall API", err);
      //makeSelectApproveAPICall();
    } finally {
    }
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <Loading
          loadingText={"Please wait we are fetching best loan offers for you."}
        />
      ) : (
        <div className={styles.auth_form}>
          <Typography component="div" my={3} ml={3}>
            <Link to="/application/home" className={classes.backToHome}>
              &#60; Back to Home
            </Link>
          </Typography>
          <Grid
            container
            spacing={0}
            sx={{ width: "auto", height: "auto" }}
            mx={3}
            mt={10}
          >
            <Grid
              item
              xs={4}
              className={classes.bankInformationList}
              sx={{
                borderTopLeftRadius: "12px",
                borderBottomLeftRadius: "12px",
              }}
            >
              <Typography component="div" my={3} ml={3}>
                <img src={kyc} height="200px" />
              </Typography>
            </Grid>
            <Grid
              item
              xs={7}
              className={classes.bankInformationList}
              sx={{
                borderTopRightRadius: "12px",
                borderBottomRightRadius: "12px",
              }}
            >
              <Typography component="div" variant="h3" my={2} color="#196aab">
                FinVu Confirmation
              </Typography>
              <Typography component="div" my={2} color="#196aab">
                <span style={{ fontWeight: "bold" }}>Note :</span> Thank you for
                sharing your details with our trusted Bankers and Please click
                below button to know your Loan Offers
              </Typography>
              <Typography
                component="div"
                my={2}
                //align="center"
                color="#196aab"
              >
                <Button
                  button_type={buttonTypes.primary}
                  button_text="Get Loan Offers"
                  button_hover_type={buttonTypes.primary_hover}
                  onClick={(e) => {
                    makeSelectApproveAPICall();
                  }}
                >
                  AGREE
                </Button>
              </Typography>
            </Grid>
          </Grid>
        </div>
      )}
    </React.Fragment>
  );
}

export default FinVuPage;
